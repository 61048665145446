.contenedorAniadirArchivo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90%;
    margin-top: 2%;
}

.contendorSuperiorAAA {
    display: flex;
    align-items: center;
    justify-content: left;
    /* background-color: #A7C0D2; */
    width: 90%;
    height: 13%;
    margin-bottom: 25px;

}

.contenedorBotonSubirAAA {
    display: flex;
    align-items: center;
    justify-content: right;
    width: 20%;
    height: 50%;
    /* background-color: bisque; */
}

.contendorBotonCargarAAA {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    height: 50%;
    /* background-color: blueviolet; */
}

.contenedorBarraAAA {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    /* background-color: black; */
    width: 60%;
    height: 50%;
}

.barraAAA {
    display: flex;
    justify-content: left;
    height: 100%;
    width: 80%;
    background-color: white;
    border: 1px solid #042354;
    border-radius: 5px;
}

.barraAAA p {
    height: 100%;
    /* background-color: #0072BC; */
}

.btnSubirAAA,
.btnCargarAAA {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    width: 60%;
    height: 100%;
    /* background-color: black; */
    /* height: 100% !important; */
    /* background-color: yellowgreen; */
}

.btnSubirAAA button,
.btnCargarAAA button {
    width: 100%;
    height: 100%;
    background-color: #0072BC;
    color: white;
    font-weight: 500;
    border: 0px;
    border-radius: 8px;

}

.btnSubirAAA button {
    background-color: #ADADAD !important;
}

.contendorInferiorAAA {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 5px;
    width: 90%;
    height: 100%;
    border: 1px solid #042354;
    border-top: 15px solid #042354;
}

.contenedorBarraBuscadoraAAA {
    display: flex;
    align-items: center;
    justify-content: left;
    width: 95%;
    padding-top: 5px;
    height: 10% !important;
    /* background-color: #042354; */
}

.contenedorBarraBuscadoraAAA {
    /* background-color: #ADADAD; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}



.contenedorTablaAAA p {
    font-size: 22px;
}





.contenedorPaginacionAAA {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 7%;
    /* background-color: #0072BC; */
}

.contenedorPaginacionAAA .my-previous-button,
.contenedorPaginacionAAA .my-next-button,
.contenedorPaginacionAAA .my-page-class {
    border-radius: 5px;
    margin: 5px;
    width: 35px;
    height: 35px;
    font-weight: normal;
    font-size: 15px;
}

.contenedorBarrainferior {
    display: flex;
    justify-content: flex-end;
    width: 95%;
    height: 15%;
}

.contenedorBotonDeshabilitarAAA {
    display: flex;
    width: 100%;
    height: 100%;
}


.btnDeshabilitarAAA {
    margin-bottom: 25px;

}

.btnDeshabilitarAAA button {

    background-color: #ADADAD;
    width: 100%;
    height: 100%;
    color: white;
    font-weight: bold;
    font-size: small;
    border: 0px;
    border-radius: 10px;


}

.btnExcelUsuario {
    background-color: #042354;
    width: 135px !important;
}

.btnGuardarAAA {
    background-color: #042354;
}

.contenedorFormAAA {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    /* flex-direction: column; */
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 1px solid #042354;
    border-top: 15px solid #042354;
}

.formAAA {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95%;
    /* background-color: #0072BC; */
}

.inputAAA {
    align-items: center;
    justify-content: center;
    text-align: left;
    width: 90%;
    /* background-color: #042354; */
}

.submitAAA {
    align-items: center;
    justify-content: center;
    text-align: right;
    width: 30%;

    /* background-color: aquamarine; */
}


.btnEnviarAAA {
    background-color: #042354;
}


.textoAAA {
    border: 1px solid black;
    width: 100%;
    border-radius: 5px;
    /* background-color: #ADADAD; */
}

.botonAceptarAA {
    background-color: #042354;
    width: 120px;
    height: 35px;
    font-size: 80%;
    margin-left: 10%;
    font-weight: bold;
}

.botonCancelarAA {
    background-color: #9E0520;
    width: 120px;
    height: 35px;
    font-size: 80%;
    margin-right: 10%;
    font-weight: bold;
}

.CodigoAAA {
    width: 12%;
}

.NombreAAA {
    width: 32%;
}

.CorreoAAA {
    width: 22%;
}

.CorreoSecAAA {
    width: 22%;
}

.CelularAAA {
    width: 12%;
}

.ColumnaAAA {
    background-color: #F2F3F3;
}


/*Paginacion*/
.pagination {
    margin: 30px 35px 0;
    text-align: right;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    list-style: none;
    color: #042354;
}

.activePage,
.pagination a:active {
    background-color: #042354;
    border: 1px solid #042354;
    color: #fff;
}

.pagination button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 14px;

    color: #042354;
    text-decoration: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
}

.my-page-class {
    font-weight: normal;
    font-size: 15px;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 14px;

    color: #042354;
    text-decoration: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
}

.pagination a:hover,
.pagination button:hover {
    color: white;
    background-color: rgba(0, 114, 188, 0.5);
    border: 1px solid #042354;
    border-radius: 5px;
    padding: 8px 14px;
    font-weight: bold;
}

.pagination button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.my-previous-button,
.my-next-button,
.my-page-class {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.my-previous-button,
.my-next-button {

    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    color: #042354;
    text-decoration: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
}

.my-previous-button.disabled,
.my-next-button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    color: #ccc;
    border-radius: 5px;
}

.my-page-link-class {
    color: black;
    text-decoration: none;
}

.my-page-link-class:active {
    color: white;
    /* Cambia esto al color deseado */
}