.input-correo {

    max-width: 281.5px;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    right: 90px;
    font-size: 0.9rem;
    height: calc(2.5rem + 2px);


}

.boton-correo {

    background-color: #042354;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px !important;
    cursor: pointer;
    font-size: 14px;
    margin-top: 20px;
    margin-left: 370px;
    font-family: 'Montserrat', sans-serif;

}

.boton-correo:hover {


    background-color: #02408C;


}