.contenedorTablaRMEM {
    width: 95%;
    max-height: 90% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

}

.contenedor-principal {
    width: 95%;
}

.miTabla {
    cursor: pointer;
}

.inputRMEM {
    border-radius: 5px 5px 5px 5px;
    /* Esquina superior izquierda y esquina inferior derecha redondeadas */
    border: 0.5px solid #CCCCCC;
    /* Opcional: agrega un borde */
    padding: 10px;
}

.btnGuardarRMEM {
    background-color: #042354;
}

.SubContenedorTablaEM td,
.SubContenedorTablaEM th {
    border-bottom: 1px solid #A7C0D2 !important;
    border-top: 1px solid #A7C0D2 !important;
    border-left: 0px;
    border-right: 0px;
    padding: 10px;
    overflow: auto;
    width: 100%;
    color: #042354;
    height: 10px !important;
}

.SubContenedorTablaEM th {
    background-color: #EEF1F1;
    font-weight: bold;
    font-size: medium;

    /*esto es para que la barra se mantenga cuando buscas en un sin fin de rows*/
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
}

.seleccionTH {
    max-width: 140px !important;
    width: 140px !important;
}

.cicloTH {
    max-width: 160px !important;
    width: 160px !important;
    font-weight: bolder;
}

.fechaLimiteTH {
    max-width: 180px !important;
    width: 180px !important;
}

.espacioTH {
    max-width: 250px !important;
    width: 250px !important;
}

.muestraTH {
    max-width: 140px !important;
    width: 140px !important;
}

.estadoTH {
    max-width: 160px !important;
    width: 160px !important;
}

.enviadoTH {
    max-width: 160px !important;
    width: 160px !important;
}

.SubContenedorTablaEM tr td {
    background-color: white;
    height: 10% !important;
}

.SubContenedorTablaEM table {
    border-collapse: collapse;
    letter-spacing: 1px;
    font-family: 'Fira Sans', sans-serif;
    font-size: 0.8rem;
    font-weight: bold;
    width: 100%;
    min-height: 40%;
    table-layout: fixed;
    text-align: justify;
    align-items: center;
    justify-content: center;
    overflow: scroll;
    color: #042354;
    border: 1px solid #A7C0D2;
    line-height: 2;

}

.checkboxEM {
    width: 60%;
    text-align: center;
    overflow: hidden;
    font-size: medium;
    /* font-weight: bolder; */
}


.paginationEM {
    display: flex;
    justify-content: center;
    padding-left: 0%;
    margin-bottom: 0%;
    list-style: none;
}

/*SCROLLBAR**/




.barraSuperiorEM {
    display: flex;
    align-items: flex-start;
    justify-content: left;
    width: 100%;
    height: 10%;
    margin: 25px 25px 5px 25px;
}

.contenedorBarraEM {
    width: 100%;
    height: 50% !important;
}



.contenedorBarraEM {
    width: 70%;
}

/*botones*/
.botonesSuperioresEM {
    display: flex;
    justify-content: flex-end;
    width: 40%;
    height: 100%;
    margin-right: 10px;
}

/**BARRA DE BUQEUDA FIN****/

.SubContenedorTablaEM {
    height: 100%;
    /* background-color: #042354; */
}

.barraInferiorEM {
    font-size: 1rem;
    /* background-color: #6c757d; */
}

.my-previous-buttonEM,
.my-next-buttonEM {
    color: #042354;

}

.my-page-classEM {
    /* background-color: grey; */
    color: white;
    padding: 8px;
}

.my-page-link-classEM {
    text-decoration: none;
    color: #042354;
}


.my-next-classEM,
.my-previous-classEM {
    color: white;
    padding: 8px;
    border-radius: 10px;
}

.my-previous-classEM:hover,
.my-next-classEM:hover {
    background-color: #042354;

}

.my-previous-buttonEM:hover,
.my-next-buttonEM:hover {
    color: white;
}

.my-page-classEM:hover {
    border: 1px solid #042354;
    color: white;
    border-radius: 10px;
}
.estado2{
    font-size: 11px !important;
}

.estado3{
    font-size: 11px !important;
}

.estado2 {
    background-color: #a7f3d0;
    color: #1d8b6a;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
    font-size: 80%;
    text-transform: uppercase;
    font-weight: bold;
    padding: 3px 12px;
    border-radius: 30px;
    width: 100%;
    height: 90%;
    text-align: center;
}

.estado3 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 80%;
    text-transform: uppercase;
    font-weight: bold;
    padding: 3px 12px;
    border-radius: 30px;
    width: 100%;
    height: 90%;
    background-color: #fecaca;
    color: #b91e1e;
    text-align: center;
}


.estadoActivo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 11px !important;
    text-transform: uppercase;
    font-weight: bold;
    padding: 3px 12px;
    border-radius: 30px;
    width: 100%;
    height: 90%;
    background-color: #ffdf77;
    border: 1px solid white !important;
    color: #ffa600;
    text-align: center;
}