/*CONTENEDORES*/
.contenedorPrincipalRECP {
    display: flex;
    flex-direction: column;
    font-family: 'Fira Sans', sans-serif;
    color: #042354;
    margin: 1.5% 0% 1.5% 0%;
    width: 95%;
    border: 1px solid black;
    border-radius: 5px;

    border: 1px solid black;
    padding: 2%;
    background-color: #fff;
    text-align: left;
    font-size: 20px;

    justify-content: left;
    align-items: left;
    margin-top: 2%;
    min-height: 95%;
}

.contenedorDatosSuperioresRECP {
    margin-bottom: 40px;
}

.contenedor-codigoRECP {
    display: flex;
    align-items: center;
}

.contenedor-evidenciaRECP {
    display: flex;
    align-items: center;
}

.contenedor-descripcionRECP {
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.contenedor-guardarRECP {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 7%;
}

.contenedor-indicadoresRECP {
    height: 60%;
}

.contIndicRECP {
    border: 1px solid black;
    padding: 2%;
    background-color: #fff;
    border-radius: 5px;
    margin-top: 2px;
    width: 100%;
    border-top: 15px solid #042354;
}

.contenedor-botonesRECP {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 20%;
}

.contenedor-indicadores-rellenoRECP {
    width: 100%;
    height: 100%;
    padding-top: 2%;
    font-weight: bold;
    color: #0072BC;
    align-items: flex-start;
    display: flex;
    justify-content: center;
    font-size: 16px;
}

.contDatosRECP {
    width: 95%;
    height: 5%;
}

.filaRECP {
    text-align: center;
}

.codigoRECP,
.descripcionRECP,
.evidenciaRECP {
    width: 15%;
    color: #042354;
}


.form-control1RECP {
    height: calc(2rem + 2px);
}

.form-control2RECP {
    height: calc(4rem + 2px);
    text-align: start;
}

/*TABLA*/
.SeleccionRECP {
    width: 12%;
}

.CodigoRECP {
    width: 18%;
}

.IndicadorRECP {
    width: 70%;
}

.ColumnaRECP {
    background-color: #F2F3F3;
}



/*BOTONES*/

.botonesSuperioresRECP {
    display: flex;
    justify-content: flex-end;
}


.btnAniadirRECP {
    background-color: #0072BC;
}

.btnEliminarRECP {
    background-color: #9E0520;
}

.btnGuardarRECP button {
    background-color: #ADADAD;
    width: 115px;
    height: 35px;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: 14px;
    border: 0px;
    border-radius: 10px;
}


.botonAceptarRECP {
    background-color: #042354;
    width: 120px;
    height: 35px;
    font-size: 80%;
    margin-left: 10%;
    font-weight: bold;
}

.botonCancelarRECP {
    background-color: #9E0520;
    width: 120px;
    height: 35px;
    font-size: 80%;
    margin-right: 10%;
    font-weight: bold;
}

.contGuardarCA {
    display: flex;
    width: 100%;
    align-items: end;
    justify-content: right;
}
.contTablaCP{
    align-items: start !important;
    height: auto !important;
}
.contenedor-indicadores-rellenoREAC{
    width: 100%;
    height: 100%;
    padding-top: 2%;
    padding-bottom: 2%;
    font-weight: bold;
    color: #0072BC;
    align-items: flex-start;
    display: flex;
    justify-content: center;
    font-size: 16px;
}