.espacioTablaREOE {
    width: 95%;
}

.espacioTablaREOE p {
    font-weight: bold;
}

.miTabla {
    cursor: pointer;
}

.inputEspecialREOE {
    border: none;
    color: red;
}

.inputREOE {
    width: 90% !important;
}

.contenedorTablaREOE {
    width: 95%;
    max-height: 90% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inputDatosREOE {
    width: 40%;
}

.modalAnchoPersonalizadoREOE {
    align-items: center;
    justify-content: center;
}

.modalAnchoPersonalizadoREOE .modal-dialog {
    max-width: 600px;
    /* Ancho máximo deseado del modal */
    margin: 0 auto;
    /* Centrar horizontalmente el modal */

}

.espacioSupModalREOE {
    width: 100%;
    text-align: right;
}

.botonCerrarREOE {
    background-color: white;
    border: 0px;
}

.seleccionableREOE {
    width: fit-content;
}

.espacioTablaREOE {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.espacioTablaGO p {
    font-weight: bold;
}

.checkboxREOE {
    width: 100%;
    text-align: center;
    overflow: hidden;
    font-size: medium;
    /* font-weight: bolder; */
}


.paginationREOE {
    display: flex;
    justify-content: center;
    padding-left: 0%;
    margin-bottom: 0%;
    list-style: none;
}

/*SCROLLBAR**/


/**** FIN BARRA DE BUSQUEDA ****/


.barraSuperiorREOE {
    display: flex;
    align-items: flex-start;
    justify-content: right;
    width: 100%;
}

.barraBotSup {
    margin-top: 24px;
}



.btnAniadirREOE {
    background-color: #0072BC;
}



.barraIntermediaREOE {
    display: flex;
    align-items: center;
    justify-content: right;
    width: 100%;
    height: 50%;
    margin-bottom: 2%;

    /* background-color: #0072BC; */
}


.btnDeshabilitarREOE {
    background-color: #9E0520;
}


/**BARRA DE BUQEUDA FIN****/


.barraInferiorREOE {
    font-size: 1rem;
    /* background-color: #6c757d; */
}

.botonAceptarrREOE {
    background-color: #042354;
}

.botonAceptarObCreadoREOE {
    background-color: #042354;
    width: 120px;
    height: 35px;
    font-size: 80%;
    margin-left: 10%;
    font-weight: bold;
    border: 0;
    padding: 0%;
    margin: 0;
    text-align: center;
    align-items: center;
}


.modalObjetivoCreadoREOE {
    justify-content: center;
    align-items: center;
    text-align: center;
}

.botonCerrarREOE {
    align-items: end;
}

.botonCancelarREOE {
    background-color: #9E0520;
}

.contenedorGO {
    display: flex;
    justify-content: space-between;
}

.modalAnchoPersonalizadoGO .modal-dialogGO {
    max-width: 40%;
    /* Ajusta el valor según tus necesidades */
}


.derechaGO {
    text-align: left;
    width: 30%;
}

.izquierdaGO {
    width: 70%;
    text-align: left;
}

.contenedorInferiorREOE {
    display: flex;
    justify-content: center;
    /* Centrar horizontalmente */
    align-items: center;
    /* Centrar verticalmente */
}

.contenedorREOE {
    margin-left: 25px;
}

.formDescr {
    height: 115px !important;
}