.inicioPaginaMM {
    display: flex;
    flex-direction: column;
    font-family: 'FiraSans', sans-serif;
    color: #042354;
    background-color: white;
    margin: 1.5% 0% 1.5% 0%;
    border: 1px solid black;
    border-radius: 5px;
    align-items: flex-start;
    width: 95%;
}

.inputDisMM {
    background-color: #F2F7F9;
    color: #7892A4;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 12px;
}

.contenedorSuperiorMM {
    width: 100%;
}

.contenedorInferiorMM {
    width: 100%;
}

.labelMM,
.tituloMM {
    font-weight: bold;
}

.TituloPrinMM {
    font-size: 20px;
}

.labelResu {
    color: #fbb917 !important;
}

.contenedorMM {
    width: 100%;
}

.posBtnEditarMMbutton {
    background-color: #042354;
    color: white;
    width: 11%;
}

.contenedorSuperiorMM {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.seccionMM {
    margin: 1px !important;
    font-size: 80%;
}

.sigBtnMM {
    width: 0;
    height: 0;
    border-style: solid;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #042354;
    border-right: 0;
    background-color: white;
    border-radius: 20%;
    margin-top: 10px;
}
.sigBtnMMGris {
    width: 0;
    height: 0;
    border-style: solid;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #797D81;
    border-right: 0;
    background-color: white;
    border-radius: 20%;
    margin-top: 10px;
    
    pointer-events: none;
    cursor: none !important;
}

.antBtnMM {
    width: 0;
    height: 0;
    border-style: solid;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 15px solid #042354;
    border-left: 0;
    background-color: white;
    border-radius: 20%;
    margin-top: 10px;
}
.antBtnMMGris {
    width: 0;
    height: 0;
    border-style: solid;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 15px solid #797D81;
    border-left: 0;
    background-color: white;
    border-radius: 20%;
    margin-top: 10px;
    pointer-events: none;
    cursor: none !important;
      
}

.cuentaMM {
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 25px;
    background-color: #D9DFDF;
    border-radius: 10%;

}

.izquierdaMM {
    text-align: left;
}

.derechaMM {
    flex-grow: 1;
    text-align: left;
}

.posBtnEditarMM {
    text-align: right;
}

.seccionMM {
    width: 10%;
}



.alineacionMM {
    flex: 1;
    text-align: left;
}

.contDatosMM {
    width: 100%;
    height: 200%;

}

.bloc-tabs {
    width: 100%;
}

.TituloGeneralMM {
    margin-left: 5%;
}



.contPerfilMM {
    border: 1px solid black;
    padding: 2%;
    background-color: #fff;
    border-radius: 5px;
    border-top: 15px solid #042354;
    max-height: 800px;
    overflow-y: auto;
}

.botonesMM {
    width: 30%;
}

.botonesMMbutton {
    width: 20%;
}

.tituloMM {
    background-color: #fff;
    padding: 5px;
    border-radius: 5px;
    margin-top: -47px;
    border: 1px solid black;

}

.btnCargarMM {
    margin: 20px;
}

.inputMM {
    background-color: white;
    color: #042354;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 12px;
}

.botonAceptarMM {
    background-color: #042354;
    width: 120px;
    height: 35px;
    font-size: 80%;
    margin-left: 10%;
    font-weight: bold;
}

.botonCancelarMM {
    background-color: #9E0520;
    width: 120px;
    height: 35px;
    font-size: 80%;
    margin-right: 10%;
    font-weight: bold;
}

.contenedorMM {
    display: flex;
    justify-content: space-between;
}

.txtResultado {
    width: 70%;
    padding-top: 7px;
}

.resultadoBoton {
    display: flex;
    justify-content: center;
    margin: 0px 5% 0px 0px;
}

.cuadroNumeroMM {
    border-radius: 10px;
    margin: 0 auto;
}

.controlesMM {
    display: flex;
}

.cuadroNumeroMMbutton {
    height: 5%;
    font-size: 10px;
    border-radius: 50%;

    cursor: pointer;
    margin: 0 !important;
}

.cuadroNumeroMM {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
}

/*Pruebatabla*/

.tablaEncabezadoMM {
    background-color: #f2f2f2;
}

.tablaSuperiorMM {
    background-color: #042354;
    color: #042354;
    height: 10px !important;
}

.tablaInferiorMM {
    background-color: white;
}

input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: black;
    content: '';
    display: inline-block;
    visibility: visible;
}

.checkboxgroup {
    display: inline-block;
    text-align: center;
}

.checkboxgroup label {
    display: block;
}

.btnGuardarMedi1 {
    background-color: #042354;
}


.botoncontenedorRMV {
    display: inline-block;
    height: 40px !important;
    width: 10%;
}

.tabs {
    padding: 15px 15px 0px 15px;
    text-align: center;
    background: #D9DFDF;
    cursor: pointer;
    border: none;
    box-sizing: content-box;
    position: relative;
    outline: none;
    padding-left: 35px;
    padding-right: 35px;
    border-radius: 7px;
    border-bottom: 15px solid #042354;
}

.tabs:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.274);
}

.active-tabs {
    background: #042354;
    color: white;
    border-bottom: 1px solid transparent;
    border-radius: 5px 5px 0px 0px !important;
}

.active-tabs::before {
    content: "";
    display: block;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100%+2px);
    height: 5px;
}


.punts {
    padding: 0px 10px 0px 10px;
    text-align: center;
    background: #D9DFDF;
    color: #042354;
    cursor: pointer;
    border: none;
    box-sizing: content-box;
    position: relative;
    outline: none;
    
    border-radius: 7px;
}

.punts:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.274);
}

.active-punts {
    background: #0072BC;
    color: white;
}

.active-punts::before {
    content: "";
    display: block;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100%+2px);
    height: 5px;
}


.content-tabs {
    flex-grow: 1;
    margin-top: -10px;
    width: 95%;
}


.rectanguloBorder {
    background-color: white;
    height: 30px;
    justify-content: center;
    color: #042354;
    font-weight: bold;
    font-size: small;
    border: 0px;
    margin-left: 27%;
    margin-right: 27%;
    border-radius: 10px;
}

.contGuardarRMMM {
    /* 
    margin-top: 20px;
     */
}
.contenedorTablaIndicadores{
    width: 95%;
    margin-bottom: 30px;
    min-height: 200px;
    max-height: 350px;
    overflow-x: auto;  
}

.rectanIndic{
    width: 300px;
    background-color: #A7C0D2 !important;
}
.rectanIndic2{
    width: 200%;
}
.contTablaIndInf{
    width: 100% !important;
}

.tablaMM{
    width: 100%;
}

.tablaEncabezadoMM td {
    white-space: nowrap;
    min-width: 100px; /* Puedes ajustar este valor según tus necesidades */
  }

.tablaMM3 {
    /* 
    width: 150%;
     */
    /*table-layout: auto;*/
}

.tablaMM3 td{
    padding: 10px;
}
.cabeceraIndic,
.tablaInferiorMM td.indicadoresClass{
    
    min-height: 200px;
    max-height: 200px;
}

.tablaInferiorMM{
    min-height: 200px;
}

.tablaMM.tablaMM3 .cabeceraIndic,
.tablaMM.tablaMM3 .indicadoresClass {
  min-width: 300px; /* Puedes ajustar este valor según tus necesidades */
  white-space: normal;
}