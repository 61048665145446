.contenedorActividadRECA,
.contenedorCodigoRECA,
.contenedorEvidenciaRECA,
.contenedorResponsableRECA {
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.contenedorDatosSuperioresRECA {
    height: 35%;
    margin-left: 30px;
}

.contenedorPrincipalRECA {
    display: flex;
    flex-direction: column;
    font-family: 'Fira Sans', sans-serif;
    color: #042354;
    margin: 1.5% 0% 1.5% 0%;
    width: 95%;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 20px;
    border: 1px solid black;
    padding: 2%;
    background-color: #fff;
    text-align: left;

    justify-content: left;
    align-items: left;
    margin-top: 2%;

    min-height: 95%;
}

.codigoRECA,
.actividadRECA,
.responsableRECA,
.evidenciaRECA {
    width: 150px !important;
    color: #042354;
    height: 20%;
}

.inputActivo {
    padding: 0 10px;
    background-color: white;
    color: #042354;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 15px;
}

.btnGuardarCA {
    background-color: #042354;
}

.contGuardarCA {
    display: flex;
    width: 100%;
    align-items: end;
    justify-content: right;
}

.inputDisRCA {
    padding: 2px 10px 2px 10px !important;
}

.inputMP {
    border-width: 1px;
    background-color: #F2F7F9;
    color: #667D8D;
    border-radius: 5px;
    width: 100%;
    height: 30px;
    font-size: 15px;
    padding: 15px 10px 15px 10px;
    margin-bottom: 10px;
}