.inicioPaginaDI {
    display: flex;
    flex-direction: column;
    font-family: 'Fira Sans', sans-serif;
    font-size: 20px;
    color: #042354;
    background-color: white;
    margin: 2% 0% 1.5% 0%;
    width: 95%;
    border: 1px solid black;
    border-radius: 5px;
    align-items: flex-end;
    padding: 2%;
    min-height: 90%;
}

.inputDisDI {
    background-color: #F2F7F9;
    color: #7892A4;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 12px;
}

.contenedor-rub {
    display: flex;
    align-items: center;
    margin-bottom: 2%;
}

.contenedorSuperiorDI {
    width: 100%;
    margin-left: 50%;
}

.ingresoCodigoDI,
.ingresoNombreDI {
    margin-left: 5%;
}

.ingresoNombreDI {
    padding: 2px 10px 2px 10px !important;
    resize: none;
}


.labelDI,
.tituloDI {
    width: 10%;
    color: #042354;
    font-weight: bold;
    height: 7%;
    display: flex;
    align-items: center;
}

.posBtnGuardarDI button {
    background-color: #042354;
    color: white;
    width: 100px;
    height: 30px;
    justify-content: center;
    font-weight: bold;
    font-size: small;
    border: 0px;
    border-radius: 10px;
}

.contenedorSuperiorDI {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 25%;
}

.inputNombreDI {
    height: 50px;
}

.contenedorInferiorDI {
    justify-content: space-between;
    align-items: center;
}

.izquierdaDI {
    text-align: left;
}

.derechaDI {
    flex-grow: 1;
    text-align: left;
}

.posBtnGuardarDI {
    text-align: right;
}

.btnGuardarDI {
    color: white;
    width: 100%;
}

.contenedorDI {
    display: flex;
    justify-content: space-between;
}


.alineacionDI {
    flex: 1;
    text-align: left;
}

.contDatosDI {
    width: 100%;
    
}

.contfotoPerfilDI {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contfotoPerfilDI p {
    font-size: 50px;
    font-weight: bold;
}

.fotoPerfilDI {
    height: 170px !important;
    max-width: 25%;
    max-height: 25%;
}



.contIndicadoresAI {
    border: 1px solid black;
    padding: 2%;
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    border-top: 15px solid #042354;
    margin-bottom: 30px;
}

.tituloDI {
    background-color: #fff;
    padding: 5px;
    border-radius: 5px;
    margin-top: -55px;
    border: 1px solid black;
    width: 17%;
    height: 9%;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-family: 'Fira Sans', sans-serif;
}


.inputDI {
    padding: 0 10px;
    color: #042354;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 1rem;
}

.botonAceptarDI {
    background-color: #042354;
    width: 120px;
    height: 35px;
    font-size: 80%;
    margin-left: 10%;
    font-weight: bold;
}

.botonCancelarDI {
    background-color: #9E0520;
    width: 120px;
    height: 35px;
    font-size: 80%;
    margin-right: 10%;
    font-weight: bold;
}


.posBtnGuardarDI button {
    background-color: #042354;
    color: white;
    width: 100px;
    height: 30px;
    justify-content: center;
    font-weight: bold;
    font-size: small;
    border: 0px;
    border-radius: 10px;
}

.posBtnGuardarDI {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    width: 95%;
    height: 7%;
    margin-top: 10px;
}

.btnGuardarDI {
    color: white;
    width: 100%;

}

.form-control1 {
    font-size: 1rem;
    background-color: #F2F7F9;
    height: calc(2rem + 2px);
}

.form-control2 {
    font-size: 1rem;
    background-color: white;
    height: calc(2rem + 2px);
}