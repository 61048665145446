@import '~typeface-lato';


@media (max-width: 1100px) {
    /* Estilos para pantallas más pequeñas */
    .contenedorBotonesSuperiores {
      display: none !important;
    }
    
    .mitadColor {
        display: none !important;
    }

    .txtBienvenida {
        margin: 3% 5% 5% 5% !important;
        height: 10% !important;
    }

    .roles{
        height: 60% !important;
        margin-bottom: 4%;
    }
    .txtBienvenida p {
        font-size: 1.4rem !important;
        margin: 5% !important
    }

    .txtSeleccion{
        margin-top: 0px !important;

    }
    .txtSeleccion p{
        font-size: 1.1rem !important;
        text-align: center !important;
        height: auto !important;
        margin: 5% 3% 5% 3% !important;
    }
    .listaRoles {
        font-size: 0.8rem !important;
        width: auto !important;
    }
    .desapereceee{
        display: none !important;
    }

    .contenedorBotonesInferioress{
        display: flex !important;
    }
    .logoUnico{
        display: flex !important;
        margin: 3% 5% 0% 5% !important;
        height: 10% !important;
        
    }
    .logo {
        background-color: transparent !important;
        width: 35%!important;
        max-height: none !important;
    }
    
  }

.InicioRoles {
    /* background-color: #DDDDDD;
    position: absolute;
    width: 100%;
    height: 100%;*/
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;

    background-image: url(../images/fondoRolesNogif.png);
    background-size: cover;
    background-position: center center;

    background-color: #f7f7fc;

    /*
    background-image: url("../images/fondoRoles4.png");
    background-repeat: no-repeat;
    background-size: cover;*/

}

.logo {
    background-color: #042354;
    align-items: center;
    justify-content: center;
    width: 300px;
    max-width: 100%;

}

.logo img {
    width: 700px;
    max-width: 100%;
    height: auto;
}


.txtSeleccion p {
    font-size: 20px;
    color: #042354;
    height: 6%;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    
}

.txtSeleccion ul {
    list-style: none;
}

.txtBienvenida p {
    font-size: 32px;
    color: #042354;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-weight: bold;
}

.txtSeleccion {
    margin-top: 5%;
    margin-bottom: 5%;
    height: 90%;
}

.txtBienvenida {
    margin-top: 5px;
    margin-bottom: 5px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}



.longitud-10 {
    width: 100px;
}

.listaRoles {
    font-size: 18px;
    cursor: pointer;
    width: 310px;
    border-radius: 5px;
    padding: 5px;
    margin: 5px 5px 13px 5px;
    border-radius: 5px;
    color: black;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    /* Opción "Black" */
    padding: 5px;
    padding-left: 15px;
    margin-right: 50px;

}

.listaRoles:nth-child(odd) {
    /* background: #bcd3f8;*/

    /*transicion*/
    background-image: linear-gradient(to right, #042354 50%, #bcd3f8 50%);
    background-size: 200% 100%;
    background-position: right;
    transition: background-position 0.3s ease;
}

.listaRoles:nth-child(odd):hover,
.listaRoles:nth-child(even):hover {
    background-position: left;
    color: white;
    font-weight: bold;
}

.listaRoles:nth-child(even) {
    /*background: #fadc8f;*/

    /*transicion*/
    background-image: linear-gradient(to right, #fbb917 50%, #fadc8f 50%);
    background-size: 200% 100%;
    background-position: right;
    transition: background-position 0.3s ease;
}

.roles {
    height: 70%;
    display: flex;
    justify-content: center !important;
    /*background-color: #DDDDDD;*/
}


/*   CONTENEDORES    */
.contenedor {
    display: flex;
    overflow: hidden;
    height: 95%;
    width: 70%;
    border-radius: 15px;
}

.contenedorBotonesSuperiores {
    display: flex;
    align-items: center;
    justify-content: right;

    margin: 3.7% 3.7% 0% 3.7%;
    height: 6% !important;
    margin-right: 100px;
}

.longitud-10 {
    width: 100px;
}


.mitadColor {
    width: 50%;
    background-color: #042354;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mitadBlanca {
    width: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100%;
}

.logo {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: auto;
}


/*   BOTONES      */

.buttonMiPerfil {
    background-color: #fbb917;
}

.buttonMiPerfil:hover,
.botonCerrarSesion:hover {
    filter: brightness(1) !important;
    background-color: #02408C;
}

.botonCerrarSesion {
    background-color: #0b61ea;
    height: 42px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    text-decoration: none;
}

.contenedorCerrarSesion {
    margin-left: 20px;
}

.miListaRoles {
    width: 100%;
    height: 85%;
    overflow: auto;
    /* Añade la barra de desplazamiento si es necesario */

}
.contenedorBotonesInferioress {
    display: none;
    align-items: center;
    justify-content: center;
    height: 6% !important;
}
.logoUnico{
    display: none;
}