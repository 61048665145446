@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;1,100;1,200;1,300&display=swap');


.googleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 281.5px;
    height: 50px;
    background: #4385f4;
    position: relative;
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    cursor: pointer;
    margin: 10px;
    border-radius: 10px;
}

.row {
    box-sizing: border-box;
    margin: 0% !important;
    height: 100%;
}

.googleName {
    margin-top: 15px;
    margin-left: 20px;
    font-size: 15px;
}

.googleContainer img {
    width: 12.5%;
    height: 100%;
    object-fit: contain;
    left: 10px;
    border-radius: 3px;
    position: absolute;
}

.campus {
    width: 100%;
    height: 100%;
    position: absolute;
}


.input-group-prepend,
.input-group-append {
    display: flex;
    align-items: center;
    position: relative;
}

.input-group-prepend::before,
.input-group-append::before {
    content: "";
    display: block;
    width: 1px;
    height: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #000;
}

.input-group-prepend::before {
    left: 0;
}

.input-group-append::before {
    right: 0;
}

.input-group {
    padding: 0 10px;
}

.contenedor-ingresar-cuenta {
    position: absolute;
    width: 100%;
    height: 100%;

}

.columna {
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 0% !important;
}

.input-groupIC {
    width: 280px;
    max-width: 300px;
    display: flex;


}

.input-group-text {
    font-size: 1rem;
    width: 40px;
    cursor: pointer;
}

.form-control {
    font-size: 0.9rem;
    background-color: aliceblue !important;
    height: calc(2.5rem + 2px);
}
.contrasenia-input{
    border-radius: 0.25rem 0rem 0rem 0.25rem!important;
}
.iconoradious{
    border-radius: 0rem 0.25rem 0.25rem 0rem!important;
}
.campo-lleno {
    border-color: #0072BC !important;
    /* Cambia este valor al color deseado */
}

.ingresar-cuenta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0%;
}

.errorLogin {
    color: red;
}

.imagen {
    width: 280px;
    height: 160px;

}

.imagen2 {

    width: 300px;
    height: 180px;

}

.diseñoIniciar {
    margin-top: 5%;
    font-weight: bold;
    font-family: 'Montserrat';
    font-size: 1.6rem;
}

.btnContra {
    color: #045DBE;
}

.iniciar-sesion-btn {
    background-color: #9E0520;
    padding: 10px 20px;
    width: 155px !important;
    margin-top: 8%;
    margin-bottom: 20px;
    font-size: 16px !important;
    cursor: pointer;

    /*
    background-image: linear-gradient(to right, #042354 50%, #9E0520 50%);
    background-size: 200% 100%;
    background-position: right;
    transition: background-position 0.3s ease; */

}

.iniciar-sesion-btn:hover {

    /*background-color: #6B0415;*/
    /*background-position: left;
    filter: brightness(1) !important;*/

}



.correo-ancho {
    max-width: 281.5px;
}

@media screen and (max-width: 1000px) and (min-width: 0px) {
    .col-8{
        display: none !important;
    }

    .col-4{
        width: 100% !important;
    }

    .contenedor-ingresar-cuenta {
        /* align-items: center; */
        justify-content: center;
        text-align: center;
        align-items: center;
        align-content: center;
        background-image: url('../images/campus.png');
        background-size: cover;
    }

    .campus {
        display: none;
    }

    .imagen {
        display: none;
        width: 0%;
    }
    .imagen2 {
        height: auto;
    }

    .subtitle {
        color: white;
    }

    .divider {
        color: white;
    }
    .contra {
        color: white !important;
    }
}


@media screen and (min-width: 1000px) {
    .imagen2 {
        display: none;
    }
    
}

.correo,
.contrasenia {
    color: white;
}



.divider {
    width: 50%;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* background-color: yellow; */
}


.divider hr {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    justify-content: center;
    font-size: small;
    width: 100%;
    /* align-content: center; */

}

.contra {
    margin-top: 3%;
    margin-left: 21%;
    text-align: right;
    cursor: pointer;
    color: #4385f4;
    text-decoration: underline;
}




.formGroupInputLog {
    position: relative;
    margin-bottom: 20px;
    width: 100%;
    max-width: 300px;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.formGroupInputLog input {
    width: 280px;
    padding: 10px;
    font-size: 16px;
    height: 48px;
}

.formGroupInputLog label {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    pointer-events: none;
    transition: all 0.3s ease;
    color: #888;
    margin-left: 10px !important;
}

.formGroupInputLog label.filled {
    top: 0;
    font-size: 12px;
    color: #042354;
    background-color: aliceblue;

    border-radius: 3px;
    padding-left: 5px;
    padding-right: 5px;
}

.dot {
    height: 20px;
    width: 20px;
    margin: 0 10px;
    background-color: #717171;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
    cursor: pointer;
}

.dot.active {
    background-color: white;
}
.image-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.campus {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.dots {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #357aa3bd;
    color: white; 
    padding: 10px;
    margin-top: 100px;
    bottom: 0;
}

.dots-text {
    text-align: center;
    margin-bottom: 10px; 
    font-family: Montserrat;
    font-size: 160%;
}
.dots-text2 {
    text-align: center;
    margin-bottom: 10px; 
    font-family: sans-serif;
    font-size: 100%;
}


.dots-container{
    text-align: center;
  
}

