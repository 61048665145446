/*CONTENEDORES*/
.contenedorPrincipalAVDF {
    display: flex;
    flex-direction: column;
    font-family: 'Fira Sans', sans-serif;
    color: #042354;
    margin: 1.5% 0% 1.5% 0%;
    width: 95%;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 20px;
    border: 1px solid black;
    padding: 2%;
    background-color: #fff;
    text-align: left;

    justify-content: left;
    align-items: left;
    margin-top: 2%;
}

.contenedorDivisionAVDF {
    display: flex;
}
.contenedorDivisionAVDF2 {
    display: flex;
    margin-top: 30px;
}
.contenedorDivisionAAVDF{
    display: flex;
}

.derechaAVDF {
    flex: 1;
}

.izquierdaAVDF {
    flex: 1;
}


.contenedorDatosSuperioresAVDF {
    height: 45%;
}

.contenedor-codigoAVDF {
    display: flex;
    align-items: center;
}

.contenedor-descripcionAVDF {
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.contenedor-evidenciaAVDF {
    display: flex;
    align-items: center;
    margin-top: 2%;
}

.contenedor-guardarAVDF {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 18%;
}

.contenedor-indicadoresAVDF {
    height: 100%;
    margin-top: 20px;
}

.contIndicAVDF {
    border: 1px solid black;
    padding: 2%;
    background-color: #fff;
    border-radius: 5px;
    margin-top: 2px;
    width: 100%;
    border-top: 15px solid #042354;
    margin-bottom: 40px;
}

.contenedor-botonesAVDF {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 10%;
}

.contenedor-indicadores-rellenoAVDF {
    width: 100%;
    height: 100%;
    padding-top: 2%;
    font-weight: bold;
    color: #0072BC;
    align-items: flex-start;
    display: flex;
    justify-content: center;
}

.contDatosAVDF {
    width: 95%;
    height: 5%;
}



.codigoAVDF {
    width: 20%;
}

.descripcionAVDF,
.evidenciaAVDF {
    width: 40%;
}

.inputAVDF {
    padding: 0 10px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    color: #042354;
}

.inputEspAVDF {
    font-size: 15px !important;
    padding: 15px 10px 15px 10px !important;
}

.form-control1AVDF {
    font-size: 1rem;
    background-color: #F2F7F9;
    height: calc(2rem + 2px);
}

.form-control2AVDF {
    font-size: 1rem;
    height: calc(4rem + 2px);
    width: 100%;
}

.rectanguloAzulAVDF {
    background-color: #042354;
    height: 5%;
    width: 100%;
    margin: -0.2%;
    display: flex;
    border-radius: 5px;
}

.tituloIDsAVDF {
    margin-top: -55px;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}





/*BOTONES*/

.botonesSuperioresAVDF {
    display: flex;
    width: 20%;
    justify-content: flex-end;
}




.btnAniadirAVDF {
    background-color: #0072BC;
}

.btnEliminarAVDF {
    background-color: #9E0520;
}

.btnGuardarAVDF button {
    background-color: #ADADAD;
    width: 100px;
    height: 30px;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: small;
    border: 0px;
    border-radius: 10px;
}

.botonAceptarAVDF {
    background-color: #042354;
    width: 120px;
    height: 35px;
    font-size: 80%;
    margin-left: 10%;
    font-weight: bold;
    border: 0;
}

.botonCancelarAVDF {
    background-color: #9E0520;
    width: 120px;
    height: 35px;
    font-size: 80%;
    margin-right: 10%;
    font-weight: bold;
    border: 0;
}

.tituloPrimFacu {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
}
.titRespFac{
    width: 35% !important;
}