.dropdown-menu {
    max-height: 600%;
    overflow-y: auto;
}


.inputActivoGen2 {
    border-width: 1px;
    border-radius: 5px;
    width: 128%;
    height: 30px;
    font-size: 15px;
    padding: 15px 10px 15px 10px;
    margin-bottom: 10px;
    text-align: center;
    background-color: white;
    color: #042354;
    border: 1px solid black;
}