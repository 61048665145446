.inicioPaginaMP {
    display: flex;
    background-size: auto;
    align-items: center;
    justify-content: center;
    font-family: 'Fira Sans', sans-serif;
    color: #042354;
    background-color: transparent;
    width: 95% !important;
    height: 100%;
    border: 1px solid #ccc;
    border-radius: 15px;
    background-color: white;

}






.nuevoEditar {
    margin-top: 2% !important;
    background-color: #02408C;
}

.btnDiv {
    width: 100%;
}

.contBotonEditar {
    width: 8%;
    display: flex;
    align-items: end;
    height: 100%;
}

.btnEditarFoto {
    height: 55px;
    width: 55px;
    margin-right: 10%;
    background-color: #042354 !important;
    border-color: #042354 !important;
}

.btnEditarFoto:hover {
    background-color: #02408C !important;
    border-color: #02408C !important;

}

/*    CONTENEDORES     */
.contenedorGeneral {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 6%;
    height: 100%;
}

.contenedorFotoPerfilMP {
    display: flex;
    justify-content: center;
    width: 45%;
    align-items: center;
    margin-right: 4%;
}

.contenedorDatos {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.datos-apellidoMP {
    display: flex;
    width: 100%;
}

.mitad-containerMP {
    width: 50%;
    /* margin-right: 10%;*/
}



.fotoPerfilMP {
    max-width: 400px;
    max-height: 400px;
}


.margen {
    margin-left: 19%;
}


.datos-containerMP {
    font-size: 18px;

}

.perfil-containerMP {
    border: 1px solid #042354;
    padding: 20px;
    padding-left: 50px;
    padding-right: 50px;
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    border-top: 15px solid #042354;
}

.tituloMP {
    font-size: 20px;
    font-family: sans-serif;
    background-color: #fff;
    padding: 5px;
    border-radius: 5px;
    margin-top: -9%;
    border: 1px solid black;
    width: 70%;
    height: 60%;
    text-align: center;
    font-family: "Montserrat", sans-serif;
}


.datos-containerMP {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    width: 100%;
}

.datosDiv {
    width: 100%;
}


/*   TEXTOS     */

.labelMP {
    font-size: 16px;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
}

.inputMP {
    border-width: 1px;
    background-color: #F2F7F9;
    color: #667D8D;
    border-radius: 5px;
    width: 100%;
    height: 30px;
    font-size: 15px;
    padding: 15px 10px 15px 10px;
    margin-bottom: 10px;
}

.inputLargoMP {
    width: 85%;
}

/*PARA LAS PANTALLAS PC 125%*/
@media (min-width: 576px) and (max-width: 1600px){
    .contenedorFotoPerfilMP {
        text-align: center;
        display: flex;
        width: auto;
    }
    .fotoPerfilMP {
        width: 100%;
        margin: 0 auto;
        display: block;
    }
    .contenedorDatos {
        
    }
    .perfil-containerMP {
        padding: 10px 15px;
        border-top: 10px solid #042354;
    }
}

/*PARA CELULARES*/
@media (max-width: 576px){
    .contenedorGeneral {
        display: block;
        height: 100%;
    }
    .contenedorFotoPerfilMP {
        text-align: center;
        display: flex;
        width: auto;
    }
    .fotoPerfilMP {
        width: 115px;
        margin: 0 auto;
        display: block;
    }
    .contBotonEditar{
        width: auto;
        right: 0 !important;
        margin-bottom: 7%;
        justify-content: end;
    }
    .btnEditarFoto{
        height: auto;
        width: auto;
        margin-right: 0px;
    }

    .contenedorDatos {
        width: auto;
    }
    
    .contenedorFotoPerfilMP{
       
    }
    .perfil-containerMP {
        padding: 10px 15px;
        border-top: 5px solid #042354;
    }
    .inicioPaginaMP {
        background-color: transparent;
        height: auto;
        margin-top: 30%;
    }
    
}