.REAMContenedorPrincipal {

    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: antiquewhite; */

}

.REAMContenedorSecundario {


    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 1px solid black;
    /* padding: 5%; */

}

.buttonEliminarEAM {
    background-color: #9E0520;
}

.REAMContenedorEspacio {
    height: 5%;
    width: 90%;
    display: flex;
    text-align: left;
    justify-content: left;
    align-items: center;
    /* background-color: aquamarine; */
}

.REAMEncabezado {
    width: 20%;
    height: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    /* background-color: blue; */
}

.REAMContenedorEncabezado {
    width: 80%;
    height: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 1px solid #042354;
    z-index: 1;
    margin-top: 40px;
}

.REAMContenedor1 {


    width: 90%;
    height: 15%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 1px solid black;
    /* padding: 10%; */
}

.REAMContenedor2 {


    width: 90%;
    height: 35%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 1px solid black;

}

.REAMContenedor3 {


    width: 90%;
    height: 25%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 1px solid black;

}

.REAMContenedorFechas {
    width: 100%;
    height: 50%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    /* background-color: aliceblue; */
}

.REAMInicio {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 100%;
    /* background-color: bisque; */
}

.REAMFinal {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 100%;
    /* background-color: aqua; */
}

.REAMI1 {
    display: flex;
    text-align: center;
    justify-content: LEFT;
    align-items: center;
    width: 20%;
    height: 100%;
}

.REAMI2 {
    width: 80%;
    height: 100%;
    display: flex;
    text-align: left;
    justify-content: LEFT;
    align-items: center;
}

.REAMTablaCarga {


    width: 80%;
    height: 50%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    /* background-color: #042354; */
}

.REAMBotonesCarga {
    width: 100%;
    height: 20%;
    display: flex;
    text-align: center;
    justify-content: left;
    align-items: center;
    /* background-color: aquamarine; */
}

.REAMTablaInterna {
    width: 100%;
    height: 80%;
    display: flex;
    text-align: center;
    justify-content: right;
    align-items: center;
    /* background-color: antiquewhite; */
    border: 1px solid black;
    border-top: 12px solid #042354;
}

.REAMBotonesSubCarga {
    width: 30%;
    height: 80%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: aqua;
}

.REGMContenedorBotonAniadir {
    width: 90%;
    height: 50%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.contDatosRAEM2 {
    width: 98%;
    height: 30%;
    margin-left: 1%;
    margin-right: 1%;
}

.conteEAM {
    border: 1px solid black;
    padding: 2%;
    background-color: #fff;
    border-radius: 5px;
    border-top: 15px solid #042354;
}

.contenedor-codigoREAM {
    display: flex;

}

.codigoREAM {

    align-items: center;
}

.codigoREAM2 {
    display: flex;
    align-items: center;
}

.contenedorDVM {
    display: flex;
    width: 90%;
}

.contenedorDVM2 {
    margin-top: 15px;
    display: flex;
}

.contenedor-cmboREAM {
    display: flex;
    margin-left: 40px;
}

.labelAM {
    margin-right: 20px;
}

.inpu {
    margin-right: 1%;
}