/*CONTENEDORES*/
.contenedorPrincipalRFAE {
    display: flex;
    flex-direction: column;
    font-family: 'Fira Sans', sans-serif;
    color: #042354;
    margin: 1.5% 0% 1.5% 0%;
    width: 95%;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 20px;
    border: 1px solid black;
    padding: 2%;
    background-color: #fff;
    text-align: left;

    justify-content: left;
    align-items: left;
    margin-top: 2%;
}

.contenedorDatosSuperioresRFAE {
    height: 45%;
}

.contenedor-codigoRFAE {
    display: flex;
    align-items: center;
}

.contenedor-descripcionRFAE {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 20px;
}

.inputEspecialRFAE{
    border: none;
    color: red;
    height: 15px;
    font-size: 16px;
    background-color: white;
}

.contenedor-evidenciaRFAE {
    display: flex;
    align-items: center;
    margin-top: 2%;
}

.contenedor-guardarRFAE {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 18%;
}

.contenedor-indicadoresRFAE {
    height: 60%;
}

.SeleccionRFAE {
    width: 12% !important;
}

.CodigoRFAE {
    width: 14% !important;
}

.NombreRFAE {
    width: 24% !important;
}

.CorreoRFAE {
    width: 50% !important;
}

.filaRFAE {
    text-align: center;
}

.ColumnaRFAE {
    background-color: #F2F3F3;
}

.contIndicRFAE {
    border: 1px solid black;
    padding: 2%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    margin-top: 2px;
    width: 100%;
    border-top: 15px solid #042354;
    margin-bottom: 40px;
}

.contenedor-botonesRFAE {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 10%;
}

.contenedor-indicadores-rellenoRFAE {
    width: 100%;
    height: 100%;
    padding-top: 2%;
    font-weight: bold;
    color: #0072BC;
    align-items: flex-start;
    display: flex;
    justify-content: center;
}

.contDatosRFAE {
    width: 95%;
    height: 5%;
}



.codigoRFAE,
.descripcionRFAE,
.evidenciaRFAE {
    width: 20%;
    height: 20%;
}


.form-control1RFAE {
    font-size: 1rem;
    height: calc(2rem + 2px);
}

.form-control2RFAE {
    font-size: 1rem;
    height: calc(4rem + 2px);
    width: 100%;
}

.rectanguloAzulRFAE {
    background-color: #042354;
    height: 10px;
    width: 100%;
    margin: -0.2%;
    display: flex;
}

.tituloIDsRFAE {
    background-color: #fff;
    padding: 5px;
    border-radius: 5px;
    margin-top: -55px;
    border: 1px solid black;
    width: 35%;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-family: 'Fira Sans', sans-serif;
}





/*BOTONES*/

.botonesSuperioresRFAE {
    display: flex;
    justify-content: flex-end;
}


.btnAniadirRFAE {
    background-color: #0072BC;
}

.btnAniadirRFAE2 {
    background-color: #0072BC;
    width: 140px !important;
}

.btnEliminarRFAE {
    background-color: #9E0520;
}

.btnGuardarRFAE {
    background-color: #ADADAD;
}


.botonAceptarRFAE {
    background-color: #042354;
    width: 120px;
    height: 35px;
    font-size: 80%;
    margin-left: 10%;
    font-weight: bold;
    border: 0px;
}

.botonCancelarRFAE {
    background-color: #9E0520;
    width: 120px;
    height: 35px;
    font-size: 80%;
    margin-right: 10%;
    font-weight: bold;
    border: 0px;
}