.inicioPaginaREAEM {
    display: flex;
    flex-direction: column;
    font-family: 'Fira Sans', sans-serif;
    color: #042354;
    background-color: white;
    margin: 2% 0% 1.5% 0%;
    width: 95%;
    border: 1px solid black;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    padding: 40px 10px 25px 10px;
}


.inputDisDMM {
    background-color: #F2F7F9;
    color: #7892A4;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 12px;
}

.contenedorSuperiorREAEM {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; 
}

.labelREAEM,
.tituloREAEM {
    font-weight: bold;
}

.TituloPrinREAEM {
    font-size: 30px;
}

.posBtnEditarDMM button {
    background-color: #042354;
    color: white;
    width: 11%;
    height: 30px;
    justify-content: center;
    font-weight: bold;
    font-size: small;
    border: 0px;
    border-color: white;
}

.labelREAEM {
    width: 30%;
}

.labelREAEMperiodo {
    width: 90%;
}

.inputDis {
    margin-bottom: 10px;
}

.btnEditar button {
    width: 80%;
    height: 30px;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: small;
    border: 0px;
    border-radius: 10px;
}

.seccionDMM {
    margin: 1px !important;
    padding: 3px 14px;
    font-size: 80%;
}

.izquierdaDMM {
    text-align: left;
}

.derechaDMM {
    flex-grow: 1;
    text-align: left;
}

.posBtnEditarDMM {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.btnEditarDMM {
    color: white;
    width: 100%;

}


.contenedorREAEM {
    display: flex;
    justify-content: space-between;
}


.alineacionDMM {
    flex: 1;
    text-align: left;
}

.contDatosREAM {
    width: 98%;
    height: 30%;
    margin-left: 1%;
    margin-right: 1%;

    /* background-color: #0072BC; */
}

.TituloGeneralREAEM {
    margin-left: 5%;
}


.contMuestrasRAEM {
    border: 1px solid black;
    padding: 2%;
    background-color: #fff;
    border-radius: 5px;
    border-top: 15px solid #042354;
    margin-bottom: 40px;
}

.botonesDMM {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 10%;
}

.botonesDMM button {
    width: 50%;
}

.tituloREAEM {
    background-color: #fff;
    padding: 5px;
    border-radius: 5px;
    margin-top: -50px;
    border: 1px solid black;
    width: 25%;
    font-size: 20px;

}

.btnCargarDMM {
    margin: 20px;
    background-color: #042354;
    color: white;
    width: 100px;
    height: 30px;
    justify-content: center;
    font-weight: bold;
    font-size: small;
    border: 0px;
    border-radius: 10px;
}

.inputREAEM {
    padding: 0 10px;
    background-color: white;
    color: #042354;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 12px;
}

.botonAceptarREAEM {
    background-color: #042354;
}

.botonCancelarREAEM {
    background-color: #9E0520;
}

.izquierdaDI {
    text-align: left;
}

.derechaDI {
    flex-grow: 1;
    text-align: left;
}

.contenedorREAEM {
    display: flex;
    justify-content: space-between;
}

.espacio {
    height: 10px;
    width: 20%;
}

.contenedorBotonesRMV {
    width: 100%;
    height: 33px;
    /* background-color: #0072BC; */
    display: flex;
    /* background-color: aqua; */
}

.botoncontenedorRMV {
    width: 7%;
    height: 100%;
    /* background-color: black; */
    display: flex;
    border: 0px;
}

.botoncontenedorRMV .botones {
    height: 35px;
    font-size: small;

    background-color: #042354;
    color: white;
    border-bottom: 1px solid transparent;

    cursor: pointer;
    border: none;
    box-sizing: content-box;
    position: relative;
    outline: none;
    margin-left: 12%;
    padding-left: 20%;
    padding-right: 20%;
    border-radius: 7px;
}

.buttonREAEM {
    background-color: #042354;
}

.buttonEliminarREAEM {
    background-color: #9E0520;
}

.contenedorDC {
    display: flex;
    justify-content: space-between;
    flex: 1;
}

.derechaREAEM {
    width: 100%;
    text-align: left;
}

.izquierdaREAEM {
    width: 100%;
    text-align: right;
}

.inputREAEM {
    padding: 0 10px;
    background-color: white;
    color: #042354;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 12px;
}

.dropdown button {
    background-color: #0072BC !important;
    min-width: 70px;
    width: 160px;
}


.tablaF td:nth-child(4) {
    /*text-align: center;*/
}
