/*CONTENEDORES*/
.contenedorPrincipalAAF {
    display: flex;
    flex-direction: column;
    font-family: 'Fira Sans', sans-serif;
    color: #042354;
    margin: 1.5% 0% 1.5% 0%;
    width: 95%;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 20px;
    border: 1px solid black;
    padding: 2%;
    background-color: #fff;
    text-align: left;

    justify-content: left;
    align-items: left;
    margin-top: 2%;
}

.contenedorDatosSuperioresAAF {
    height: 45%;
    margin-bottom: 20px;
}

.inputEspecialAAF{
    border: none;
    color: red;
    height: 15px;
    font-size: 16px;
    background-color: white;
}

.contenedor-codigoAAF {
    display: flex;
    align-items: center;
}

.SeleccionAAF {
    width: 12% !important;
}


.NombreAAF {
    width: 24% !important;
}

.CorreoAAF {
    width: 50% !important;
}

.filaAAF {
    text-align: center;
}

.ColumnaAAF {
    background-color: #F2F3F3;
}

.contenedor-descripcionAAF {
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.contenedor-evidenciaAAF {
    display: flex;
    align-items: center;
}

.contenedor-guardarAAF {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 18%;
}

.contenedor-indicadoresAAF {
    height: 60%;
}

.contIndicAAF {
    border: 1px solid black;
    padding: 2%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    margin-top: 2px;
    width: 100%;
    border-top: 15px solid #042354;
    margin-bottom: 40px;

}

.contenedor-botonesAAF {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 10%;
}

.contenedor-indicadores-rellenoAAF {
    width: 100%;
    height: 100%;
    padding-top: 2%;
    font-weight: bold;
    color: #0072BC;
    align-items: flex-start;
    display: flex;
    justify-content: center;
}

.contDatosAAF {
    width: 95%;
    height: 5%;
}



.codigoAAF,
.descripcionAAF,
.evidenciaAAF {
    width: 20%;
    color: #042354;
    font-weight: bold;
    height: 20%;
}

.inputAAF {
    background-color: white !important;
    border: 1px solid black;
    color: #042354;
}


.form-control2AAF {
    font-size: 1rem;
    height: calc(4rem + 2px);
    width: 100%;
}

.rectanguloAzulAAF {
    background-color: #042354;
    height: 10px;
    width: 100%;
    margin: -0.2%;
    display: flex;
}

.tituloIDsAAF {
    background-color: #fff;
    padding: 5px;
    border-radius: 5px;

    border: 1px solid black;
    width: 30%;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-family: 'Fira Sans', sans-serif;
}





/*BOTONES*/

.botonesSuperioresAAF {
    display: flex;
    width: 20%;
    justify-content: flex-end;
}



.btnAniadirAAF {
    background-color: #0072BC;
}

.btnAniadirAAF2 {
    background-color: #0072BC;
}

.btnEliminarAAF {
    background-color: #9E0520;
}

.btnGuardarAAF {
    background-color: #ADADAD;
}


.botonAceptarAAF {
    background-color: #042354;
    width: 120px;
    height: 35px;
    font-size: 80%;
    margin-left: 10%;
    font-weight: bold;
    border: 0px;
}

.botonCancelarAAF {
    background-color: #9E0520;
    width: 120px;
    height: 35px;
    font-size: 80%;
    margin-right: 10%;
    font-weight: bold;
    border: 0px;
}

.contenedorTablaF {
    display: flex;
    align-items: center;
    justify-content: center;
}

.contenedorTablaF p {
    text-align: center;
}