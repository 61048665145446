.RMVDTitulo {
    width: 95%;
    height: 5%;
    display: flex;
    flex-direction: column;
}

.RMVDContenedorPrincipal {
    width: 95%;
    height: 95%;
    display: flex;
    flex-direction: column;
    /* background-color: aliceblue; */
    /* border: 1px solid black; */
}


.RMVDinicioPagina {
    display: flex;
    flex-direction: column;
    font-family: 'Fira Sans', sans-serif;
    color: #042354;
    background-color: white;
    margin: 1.5% 0% 1.5% 0%;
    width: 95%;
    border: 1px solid black;
    border-radius: 5px;
    align-items: center;
    padding: 30px 0px 20px 0px;
}


.contenedor-indicadoresRMVD {
    height: 95%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.contIndicRMVD {
    border: 1px solid black;
    padding: 2%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    margin-top: 2px;
    width: 100%;
    border-top: 15px solid #042354;
}

.contenedor-botonesRMVD {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    /* background-color: #0072BC; */
    width: 100%;
    height: 20%;
}

.contenedor-indicadores-rellenoREVC {
    width: 100%;
    height: 100%;
    padding-top: 2%;
    font-weight: bold;
    color: #0072BC;
    align-items: flex-start;
    display: flex;
    justify-content: center;
}

.contDatosREVC {
    width: 95%;
    height: 5%;
}

.tituloIDsRMVD {
    background-color: #fff;
    padding: 5px;
    border-radius: 5px;
    margin-top: -55px;
    border: 1px solid black;
    width: 17%;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-family: 'Fira Sans', sans-serif;
}

.contPerfilRMVD {
    width: 100%;
    height: 50%;
    /* background-color: #0072BC; */

}

.contDatosRMVD {
    width: 100%;
    height: 20%;
    /* margin-bottom: 100PX; */
    /* background-color: #0072BC; */
}

.contenedorInferiorRMVD {
    height: 80%;
    width: 100%;

    border: 1px solid black;
    /* background-color: #0072BC; */
}

.contenedorRMVDCMM,
.contenedorRMVDCM {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.contenedorGeneralRMVD {
    height: 100%;
    width: 100%;
}

.contenedorTablaRMVD {
    width: 100%;
    height: 70%;
    background-color: #0072BC;
}

.botonesRMVD {
    width: 100%;
    height: 30%;
    /* background-color:   #0072BC; */
}

.contenedorCargaRMVD {
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */

    /* background-color:   #0072BC; */
    border: 1px solid #042354;
    border-radius: 0px 5px 5px 5px;
    border-top: 15px solid #042354;
    margin-bottom: 20px !important;
}

.contenedorDC {
    /* justify-content: center; */
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;
}

.contenedorRMVDTablaAlumnos {
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* background-color: #0072BC; */
    justify-content: start;
}

.contenedorRMVVDEliminar {
    width: 100%;
    height: 50px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    /* background-color: #0072BC; */
    justify-content: center;
}

.tablaRMVDAlumnos {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    /* background-color: #0072BC; */
    justify-content: center;
}

.tablaRMVDAlumnos p {
    text-align: center;
    margin-bottom: 0px !important;
}

.contenedorRMVDDMM {
    width: 100%;
    height: 1 00%;
    display: flex;
    flex-direction: row;
    /* background-color: #0072BC; */
    justify-content: center;
    /* align-items: center; */
    margin-top: 30px;
    margin-bottom: 35px;
}

.alineacionRMVD {
    display: flex;
    flex-direction: row;
    height: 100%;
    /* background-color: #0072BC; */
    align-items: center;
    justify-content: center;
}

.contRMVDLabel {
    width: 60%;
    height: 50%;


    /* background-color: #0072BC; */
}

.contRMVDLabel2 {
    width: 150px;
    height: 50%;
    display: flex;
    text-align: start;
    /* background-color: #0072BC; */
}

.contenedorBotonRMVD {
    width: 80%;
    /* background-color: #0072BC; */
}

.buttonRMCA {
    background-color: #042354;
}

.btnEliminarRMCA {
    background-color: #9E0520;
}

.botonguardarRMVD {
    align-items: end;
    text-align: right;
}

.btnGuardarRMCA {
    margin-top: 1.5%;
    background-color: #042354;
}

.btncargaRMCA {
    background-color: #042354;
    margin-bottom: -0.5%;
    width: 135px !important;
    border-radius: 10px 10px 0px 0px !important;
    margin-left: 0px !important;
    margin-right: 1% !important;
}

#contenedorDMM {
    margin-top: 20px;
    margin-bottom: 20px;
}


.contenedorPaginacionMCA {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.izquierdaREAM {
    margin-right: 30px;
}







.activePage {
    background-color: #042354;
    border: 1px solid #042354;
    width: 40px;
    height: 40px;
    color: white !important;
    font-weight: bold;
    margin-left: 10px;
    margin-right: 10px;
    padding: 15px;
    border-radius: 5px;
}
.pagination2 {
    margin: 30px 35px 0;
    text-align: right;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    list-style: none;
    color: #042354;
}


.my-page-class2 {
    font-weight: normal;
    font-size: 15px;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 14px;

    color: #042354;
    text-decoration: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
}
.my-page-class2:hover {
    
    background-color: rgba(0, 114, 188, 0.5);
    border: 1px solid #042354;
    width: 40px;
    height: 40px;
}
.pagination2 button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.my-previous-button,
.my-next-button,
.my-page-class2{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
    width: 40px;
    height: 40px;
}

.my-previous-button,
.my-next-button {

    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    color: #042354;
    text-decoration: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
}

.my-previous-button.disabled,
.my-next-button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    color: #ccc;
    border-radius: 5px;
}

.my-page-link-class.active {
    color: pink !important;
    /* Cambia esto al color deseado */
}

.my-page-class2:active {
    color: white !important;
    font-weight: bold;
    width: 40px;
    height: 40px;
}

.my-page-link-class {
    /* color: #042354; */
    text-decoration: none;
}


.my-previous-button:hover,
.my-next-button:hover,
.my-page-class2:hover {
    background-color: rgba(0, 114, 188, 0.5);
    border: 1px solid #042354;
    color: white !important;
    font-weight: bold;
    width: 40px;
    height: 40px;

}
.contenedorPaginacionAAA {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 7%;
    /* background-color: #0072BC; */
}

.contenedorPaginacionAAA .my-previous-button,
.contenedorPaginacionAAA .my-next-button,
.contenedorPaginacionAAA .my-page-class2 {
    border-radius: 5px;
    margin: 5px;
    width: 35px;
    height: 35px;
    font-weight: normal;
    font-size: 15px;
}

.activePage{
    background-color: #A7C0D2;
    border: 1px solid black;
}