.barraSuperiorEM{
    display: flex;
    align-items: flex-start;
    justify-content: right;
    width: 100%;
    height: 10%;
    margin: 25px 25px 5px 25px;
}
.contenedorBarraEM,
.inputSolo{
    width: 100%;
}
