.RMEContenedorPrincipal {
    width: 100%;
    height: 100%;
    /* background-color: antiquewhite; */
    display: flex;

    justify-content: center;
   
}


.RMEContenedorSecundario {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
    height: 90%;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    /* background-color: aqua; */
    margin: 2% 0% 1.5% 0%;
}

.RMEContenedor1 {
    width: 90%;
    height: 80%;
    display: flex;
    /* background-color: blue; */
    justify-content: center;
    align-items: flex-end;


}

.RMEContenedorTabla {
    width: 100%;
    height: 90%;

}

.RMEContenedor2 {
    width: 90%;
    height: 20%;
    display: flex;
    /* background-color: red; */
}

.RMEContenedorBotones {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: flex-end;
    /* background-color: aquamarine; */
}


.eviEncabezado {
    font-weight: bold;
    background-color: #adc3e6 !important;
    text-transform: uppercase;
    color: white;
    vertical-align: middle;
    font-size: 14px;
}

.indicCon {
    width: 12%;
}
.compAsig{
    width: 15%;
}
.evidSol{
    width: 40%;
}
.verArch {
    width: 15%;
}

.ver {
    background-color: #699bcd !important;
    width: 35% !important;
}

.ver:hover {
    background-color: #63bcda !important;

}

.cargar2 {
    width: 45% !important;
}

.cargar2:hover {
    background-color: #1868bc !important;

}

.RMEContenedorBoton1 {
    height: 100%;
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: blanchedalmond; */
}

.btnRMEGuardar {
    width: 80%;
    height: 50%;
    background-color: #003C7A;
    color: white;
    font-weight: bold;
    border: 0px;
    border-radius: 10px;
}


.RMEmodalCargar {
    display: flex;
    justify-content: right;
    text-align: right;
    align-self: flex-end;
    /* background-color: aliceblue; */
}


.RMEContenedorCancelar {
    width: 100%;
    height: 100%;
    display: flex;
    /* background-color: #003C7A; */
    align-items: flex-end;
    justify-content: right;
}

.REGRContenedorEspacio {
    width: 50%;
    height: 100%;
    display: flex;
    /* background-color: #003C7A; */
    align-items: center;
    justify-content: center;
    /* background-color: wheat; */
    /* font-size: small; */
}

.REGRInicio {
    display: flex;
    height: 50%;
}

.REGRFinal {
    display: flex;
    height: 50%;
}

Modal footer {

    align-items: flex-end;
}

.drop-file-input {
    position: relative;
    width: 400px;
    height: 200px;
    border: 2px dashed var(--border-color);
    border-radius: 20px;

    /*display: flex;*/
    align-items: center;
    justify-content: center;
    /*margin-left: 20%;*/
    background-color: var(--input-bg);
    margin: 0 auto;
}

.drop-file-input input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}


.drop-file-input:hover,
.drop-file-input.dragover {
    opacity: 0.6;
}

.drop-file-input__label {
    text-align: center;
    color: var(--txt-second-color);
    font-weight: 600;
    padding: 10px;
}

.titulo:hover {
    color: #0036ff;
    cursor: pointer;
    text-decoration: underline;


}

.drop-file-input__label img {
    width: 100px;
}

.drop-file-preview {
    margin-top: 30px;
}

.drop-file-preview p {
    font-weight: 500;
}

.drop-file-preview__title {
    margin-bottom: 20px;
}

.drop-file-preview__item {
    position: relative;
    display: flex;
    margin-bottom: 10px;
    background-color: var(--input-bg);
    padding: 15px;
    border-radius: 20px;
}

.drop-file-preview__item img {
    width: 50px;
    margin-right: 20px;
}

.drop-file-preview__item__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.drop-file-preview__item__del {
    background-color: var(--box-bg);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: var(--box-shadow);
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.drop-file-preview__item:hover .drop-file-preview__item__del {
    opacity: 1;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap");

:root {
    --body-bg: #f5f8ff;
    --box-bg: #fff;
    --input-bg: #f5f8ff;
    --txt-color: #2f2d2f;
    --txt-second-color: #ccc;
    --border-color: #4267b2;
    --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.header {
    margin-bottom: 30px;
    text-align: center;
}

.evidenciaRowSize{
    width: 40% !important;
}

/*
.box {
    background-color: var(--box-bg);
    padding: 30px;
    border-radius: 20px;
    box-shadow: var(--box-shadow);
    align-items: center;
    justify-content: center;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    background-color: var(--body-bg);
    color: var(--txt-color);

    display: flex;
    justify-content: center;
    padding-top: 100px;

    height: 100vh;
    padding: 0;
    margin: 0;
    box-sizing: border-box;


}*/