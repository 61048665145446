.pagination {
    margin: 30px 35px 0;
    text-align: right;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.pageInfo {
    color: #a0a3bd;
    font-size: 0.874em;
    letter-spacing: 0.5px;
}

.pagination .pagebuttons {
    display: flex;
    align-items: center;
}


.pageBtn {
    border: 1px solid 042354;
    color: #a0a3bd;
    border-radius: 5px;
    margin: 5px;
    width: 35px;
    height: 35px;
    font-weight: normal;
    font-size: 15px;
}

.pagination button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    background-color: #f2f2f2;
    color: #042354;
    text-decoration: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
}

.pagination button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pagination button:hover {
    background-color: rgba(0, 114, 188, 0.5);
    border: 1px solid #042354;
    color: white;
    font-weight: bold;
}

.pagination button.active {
    background-color: #042354;
    border: 1px solid #042354;
    color: #fff;
}

.activeBtn {
    border: 1px solid 042354;
    color: blue;
    background-color: transparent;
}

.disabledPageBtn {
    background-color: #a0a3bd;
    cursor: not-allowed;
    opacity: 0.5;
}

.botonNumeros {}