.evidencias {

    overflow-y: scroll;
}

.encabezadoContra {
    font-weight: 500;
    font-size: 15px;
    color: #042354;
}

.iguales {
    color: red;
    font-size: 13px;

}

.confirmacion {

    text-align: center;
    color: #042354;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    font-size: 17px;


}

.boton-aceptar {
    background-color: #042354;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px !important;
    cursor: pointer;
    font-size: 14px;
    margin-top: 20px;
    margin-left: 40%;
    font-family: 'Montserrat', sans-serif;
}



.drop-file-input {
    position: relative;
    width: 669px;
    height: 220px;
    border: 2px dashed var(--border-color);
    border-radius: 20px;

    /*display: flex;*/
    align-items: center;
    justify-content: center;
    /*margin-left: 20%;*/
    background-color: var(--input-bg);

    margin: 0 auto;
}

.drop-file-input input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}


.drop-file-input:hover,
.drop-file-input.dragover {
    opacity: 0.6;
}

.drop-file-input__label {
    text-align: center;
    color: var(--txt-second-color);
    font-weight: 600;
    padding: 10px;
    padding-top: 30px;
}

.tituloArchivo:hover {
    color: #0036ff;
    cursor: pointer;
    text-decoration: underline;


}

.drop-file-input__label img {
    width: 100px;
}

.drop-file-preview {
    margin-top: 30px;
}

.drop-file-preview p {
    font-weight: 500;
}

.drop-file-preview__title {
    margin-bottom: 20px;
    font-weight: 600;
}

.drop-file-preview__item {
    position: relative;
    display: flex;
    margin-bottom: 10px;
    background-color: var(--input-bg);
    padding: 15px;
    border-radius: 20px;
    width: 740px;
}

.drop-file-preview__item img {
    width: 73px;
    margin-right: 30px;
    height: 75px;
}

.drop-file-preview__item__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.drop-file-preview__item__del {
    background-color: var(--box-bg);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: var(--box-shadow);
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.drop-file-preview__item:hover .drop-file-preview__item__del {
    opacity: 1;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap");

/*
:root {
    --body-bg: #f5f8ff;
    --box-bg: #fff;
    --input-bg: #f5f8ff;
    --txt-color: #2f2d2f;
    --txt-second-color: #ccc;
    --border-color: #4267b2;
    --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}*/

.cuadroArchivo {

    overflow-y: auto;

}

.cuerpo {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    background-color: var(--body-bg);
    color: var(--txt-color);

    display: flex;
    justify-content: center;
    padding-top: 100px;

    height: 100vh;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    overflow-y: auto;
}

/*
.box {
    background-color: var(--box-bg);
    padding: 30px;
    border-radius: 20px;
    box-shadow: var(--box-shadow);
    align-items: center;
    justify-content: center;

}
*/
.header {
    margin-bottom: 30px;
    text-align: center;
}