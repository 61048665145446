.contenedorEG {
    padding: 10%
}

.error {
    padding: 10px;
    display: block;
    margin: auto;
    width: 20%;

}


.tituloGmail {
    display: block;
    margin: auto;
    text-align: center;

    color: #9E0520;
    font-family: sans-serif !important;
    font-weight: bold;
    font-size: 180%;
}

.mensaje {
    text-align: center;
    color: #9E0520;
    font-family: sans-serif !important;
}


.volver {
    display: flex;
    justify-content: center;


}



.btnError {

    background-color: rgb(4, 35, 84);
    color: white;
width: 115px;
margin: 5px;
  margin-top: 55px;
  margin-left: 5px;
padding: 10px 0px;
font-weight: bold !important;
font-size: 14px !important;
font-family: sans-serif !important;
justify-content: center;
border: 0px;
border-radius: 10px;
transition: background-color 0.2s ease;
}