.inicioPaginaDC {
    display: flex;
    flex-direction: column;
    font-family: 'Fira Sans', sans-serif;
    color: #042354;
    background-color: white;
    margin: 1.5% 0% 1.5% 0%;
    width: 95%;
    /* height: 90%; */
    border: 1px solid black;
    border-radius: 5px;
    align-items: center;

    background-image: url("../images/fondoMiPerfil3.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.inputEspecialADC {
    border: none;
    color: red;
    height: 15px;
    font-size: 13px !important;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
}


.btnEditarFoto2 {
    height: 20%;
    background-color: #042354 !important;
    border-color: #042354 !important;
    display: block;
}

.posBtnEditarDC {
    height: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.btnEdicionGuardar {
    background-color: #042354;
}


.contenedorADC {
    display: flex;
    justify-content: space-between;
    flex: 1;
    align-items: center;
    text-align: left;
    padding-left: 2%;
    padding-right: 2%;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.contenedorDC2 {
    display: flex;
    justify-content: space-between;
    flex: 1;
    text-align: left;
    padding-left: 2%;
    padding-right: 2%;
}


.alineacionDC {
    width: 40%;
}

.botonEditarAlinear {
    margin: 0px !important;
}


.contDatosDC {
    width: 90%;
    height: 100%;
}

.contDatosPersonalesDC {
    height: fit-content;
    padding-bottom: 3%;

}

.contfotoPerfilDC {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border-radius: 10px; */
    width: 100%;
    /* background-color: #667D8D; */
}

.contfotoPerfilDC p {
    font-size: 40px;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    width: 40%;
    text-align: center;
    /* background-color: #667D8D; */
}

.contenedorFotoUnico {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border-radius: 10px; */
    width: 200px;
    height: 200px;
    background-image: url("../images/bordePerfilFoto.png");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 100%;
}

.fotoPerfilDC {
    height: 185px;
    width: 185px;
    min-height: 80%;
    background-color: #667D8D;
    border-radius: 100%;
    /*
    border-width: 5px;
    border-style: solid;
    border-image: url(../images/bordePerfilFoto.png) 10 round;
    border-radius: 100%;*/
}




.datos-containerDC {
    font-size: 14px;
}

.contPerfilDC {
    border: 1px solid black;
    height: 90%;
    background-color: #fff;
    border-radius: 5px;
    margin: 0% !important;
    border-top: 15px solid #042354;
}

.tituloDC {
    background-color: #fff;
    padding: 5px;
    border-radius: 5px;
    margin-top: -30px;
    border: 1px solid black;
    width: 18%;
    height: 60%;
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    margin-left: 20px;
    font-family: "Montserrat", sans-serif;
}


.inputDC {
    background-color: #F2F7F9;
    color: #667D8D;
    border: 1px solid #ccc;
    
    border-radius: 5px;
    width: 100%;
    height: 30px;
    border-width: 1px;
    font-size: 15px;
    padding: 15px 10px 15px 10px;
}

.inputDCError {
    background-color: #F2F7F9;
    color: #667D8D;
    border: 1px solid red;
    border-radius: 5px;
    width: 100%;
    height: 30px;
    font-size: 16px;
}

.inputDCError {
    background-color: #F2F7F9;
    color: #667D8D;
    border: 1px solid red;
    border-radius: 5px;
    width: 100%;
    height: 30px;
    font-size: 16px;
}

.botonCancelarDC {
    background-color: #9E0520 !important;
}

.botonAceptarDC {
    background-color: #042354 !important;
}

.modal-body p {
    font-size: 1.2rem;
    text-align: center;
    font-weight: bold
}

.modal-footer {
    justify-content: center;
}

.modal {
    font-family: 'Fira Sans', sans-serif;
    margin-top: 14%;
}

.label {
    font-size: 18px;
}

.listaRolesDC {
    margin-top: 2%;
    font-size: 18px;
    margin-bottom: 2%;
}