.input-groupBuscar {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: 5px;
}

.form-controlBuscar {
    display: flex;
    height: 40px;
    width: 90%;
    /* Cambia el valor según tu necesidad */

    max-width: 1000px;
    font-size: 14px;

    /* Cambia el tamaño de fuente según tu necesidad */
    border: 1px solid #ccc;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
}

.form-controlBuscar::placeholder {
    color: #ccc;
}

.form-controlBuscar {
    display: flex;
    height: 40px;
    width: 90%;
    /* Cambia el valor según tu necesidad */

    max-width: 1000px;
    font-size: 14px;

    /* Cambia el tamaño de fuente según tu necesidad */
    border: 1px solid #ccc;
    border-radius: 4px;
    padding-left: 10px;
}

.form-controlBuscar::placeholder {
    color: #ccc;
}

.input-group-appendBuscar .m-0 {
    width: 100%;
    height: 40px;
}

.input-group-appendBuscar button {
    background-color: #f1f1f1e7;
    color: #0072BC;
    font-weight: bold;
}


.formChiqui {
    width: 85% !important;
}

.no-rounded {
    /* Additional styling rules for the inputs */
    /* Remove any existing border radius */
    border-radius: 0;
    border-left: 0;
}

.rounded-left {
    border-top-left-radius: 10.25rem;
    border-bottom-left-radius: 10.25rem;
    border-top-right-radius: 0rem;
    border-bottom-right-radius: 0rem;
}

.btn-rounded {
    /* Additional styling rules for the button */
    /* Remove any existing border radius */
    padding-right: 15px;
}

.rounded-right {
    border-top-left-radius: 0rem;
    border-bottom-left-radius: 0rem;
    border-top-right-radius: 10.25rem;
    border-bottom-right-radius: 10.25rem;
}