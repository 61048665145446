.contedorGeneralRMDA {
    width: 100%;
    height: 100%;
}

.btnEliminarRMDA {

    background-color: #9E0520;
}

.contenedorRMDA {
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    top: 0;
    left: 0;
    height: 90%;
    width: 95%;
    margin: 2% 0% 1.5% 0%;
    padding: 20px;
}

.listaArchivosRMDA {
    width: calc(100% / 7);
    /* Dividir el ancho en 7 columnas */
    height: 133px;
    background-color: #f3f3fc;
    text-align: center;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    display: inline-block;
    margin-right: 10px;
    margin-top: 10px;
    padding: 0px;
    overflow: hidden;
    border-radius: 10px;
    padding: 10px;
}

.checkBoxRMDA {
    margin-top: 15px;
}

.parteSubRMDA {
    text-align: right;
}


.parteArchivos {
    font-size: 20px;
    color: #042354;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
    /*background-color: pink;*/
}

.iconosDisenio {
    color: #ffca1a;
    margin: 30px 45px 0px 25px;

    width: 50px;
    height: 50px;

}

.iconosDisenio1 {
    color: #1c5fc0;
    margin: 30px 45px 0px 25px;

    width: 50px;
    height: 50px;

}

.iconosDisenio2 {
    color: #33c481;
    margin: 30px 45px 0px 25px;

    width: 50px;
    height: 50px;

}

.iconosDisenio3 {
    color: #d35230;
    margin: 30px 45px 0px 25px;

    width: 50px;
    height: 50px;

}

.iconosDisenio4 {
    color: #b30b00;
    margin: 30px 45px 0px 25px;

    width: 50px;
    height: 50px;

}

.iconosDisenio5 {
    color: #71a8f3;
    margin: 30px 45px 0px 25px;

    width: 50px;
    height: 50px;

}

.parteArchivos {
    display: flex;
    flex-wrap: wrap;
}


.nombreArchivo {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}

.nombreArchivo:hover {
    white-space: normal;
    overflow: visible;
    cursor: pointer;
    color: #4385f4;
    text-decoration: underline;
}