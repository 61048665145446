.contenedorTablaGM {
    width: 95%;
    max-height: 90% !important;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.miTabla {
    cursor: pointer;
}

.seleccionableGM {
    width: fit-content;
}

.espacioTabla {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.SubContenedorTablaGM td,
.SubContenedorTablaGM th {
    border-bottom: 1px solid #A7C0D2 !important;
    border-top: 1px solid #A7C0D2 !important;
    border-left: 0px;
    border-right: 0px;
    padding: 10px;
    overflow: auto;
    width: 100%;
    color: #042354;
    height: 10px !important;
}

.SubContenedorTablaGM th {
    background-color: #EEF1F1;
    font-weight: bold;
    font-size: medium;

    /*esto es para que la barra se mantenga cuando buscas en un sin fin de rows*/
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
}

.SubContenedorTablaGM tr td {
    background-color: white;
    height: 10% !important;
}

.SubContenedorTablaGM table {
    border-collapse: collapse;
    letter-spacing: 1px;
    font-family: 'Fira Sans', sans-serif;
    font-size: 0.8rem;
    font-weight: bold;
    width: 100%;
    min-height: 40%;
    table-layout: fixed;
    text-align: justify;
    align-items: center;
    justify-content: center;
    overflow: scroll;
    color: #042354;
    border: 1px solid #A7C0D2;
    line-height: 2;

}


.checkboxGM {
    width: 100%;
    text-align: center;
    overflow: hidden;
    font-size: medium;
    /* font-weight: bolder; */
}


.paginationGC {
    display: flex;
    justify-content: center;
    padding-left: 0%;
    margin-bottom: 0%;
    list-style: none;
}

/*SCROLLBAR**/

.contenedorBarraGM {
    width: 70%;
}

/**** FIN BARRA DE BUSQUEDA ****/


.btn-outline-successGC .bi {
    display: flex;
    color: #6c757d;
}



.barraSuperiorGM {
    display: flex;
    align-items: flex-start;
    justify-content: left;
    width: 100%;
    height: 10%;
    margin: 25px 25px 5px 25px;
}


.btnAniadirGM {
    background-color: #0072BC;
}

.btnEliminarGM {
    background-color: #ccc;
}


.contenedorBarraGC {
    width: 100%;
    height: 50% !important;
}

.barraIntermediaGC {
    display: flex;
    align-items: center;
    justify-content: right;
    width: 100%;
    height: 50%;
    margin-bottom: 2%;

    /* background-color: #0072BC; */
}


/**BARRA DE BUQEUDA FIN****/

.SubContenedorTablaGM {
    height: 100%;
    /* background-color: #042354; */
}



.botonAceptarGM {
    background-color: #042354;
    width: 120px;
    height: 35px;
    font-size: 80%;
    margin-left: 10%;
    font-weight: bold;
}

.botonCancelarGM {
    background-color: #9E0520;
    width: 120px;
    height: 35px;
    font-size: 80%;
    margin-right: 10%;
    font-weight: bold;
}

.REGMContenedorPrincipal {

    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: antiquewhite; */

}

.REGMContenedorSecundario {


    width: 90%;
    height: 90%;

    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 1px solid black;

}

.REGMBarraBotones {

    width: 100%;
    height: 20%;
    /* background-color: antiquewhite; */
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: flex-end;

}

.REGMContenedorTabla {
    width: 100%;
    height: 80%;
    /* background-color: aqua; */
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.REGMBotonAniadir {
    width: 10%;
    height: 50%;
    display: flex;
    text-align: center;
    justify-content: center;
    /* background-color: black; */
}

.REGMContenedorBotonAniadir {
    width: 90%;
    height: 50%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.REGMContenedorBotonAniadir button {
    width: 100%;
    height: 80%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: #0072BC;
    color: white;
    font-weight: bold;
    border: 0px;
    border-radius: 10px;
}



/* Estilos para el botón cuando está activo */
.boton-eliminar:active {
    /* Estilos específicos cuando el botón está activo */
}

/* Estilos para el botón cuando está desactivado */
.boton-eliminar:disabled {
    /* Estilos específicos cuando el botón está desactivado */
}

.boton-eliminar {
    margin-right: 15px;
}