.btnDivDisenio {
    display: flex;
    align-items: flex-start;
    justify-content: right;

    width: 160px;
    height: 70%;
}

.btnDisenio {
    color: white;

    width: 115px;

    margin: 5px;
    padding: 10px 0px;

    font-weight: bold !important;
    font-size: 14px !important;
    font-family: sans-serif !important;

    justify-content: center;

    border: 0px;
    border-radius: 10px;

    transition: background-color 0.2s ease;
}

.btnDisenio:hover {
    filter: brightness(1.2);
}



/*para un boton largo*/
.btnDisenioLargo {
    color: white;

    width: 135px;
    height: 35px;
    margin: 5px;
    padding: 5px 10px 5px 10px;

    font-weight: bold;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;

    justify-content: center;

    border: 0px;
    border-radius: 10px;
}



/*    CONTENEDORES DE BOTONES     */

.contenedorBotonesConBusc {
    display: flex;
    align-items: center;
    justify-content: right;
    width: 30%;
    height: 100%;
}

.contenedorBotones {
    display: flex;
    align-items: center;
    justify-content: right;
    width: 100%;
    height: 100%;
}

.contGuardarFinal {
    display: flex;
    width: 100%;
    justify-content: right;
}



/* Animación de brillo */
.btnDisenioBrillo {
    color: white;

    width: 115px;

    margin: 5px;
    padding: 10px 0px;

    font-weight: bold;
    font-size: 14px;
    font-family: sans-serif;

    justify-content: center;

    border: 0px;
    border-radius: 10px;

    transition: background-color 0.2s ease;
}

.btnDisenioBrillo:hover {
    background-color: #02408C;
    animation: brillo 1s infinite;
}

@keyframes brillo {
    0% {
        box-shadow: 0 0 0 rgba(36, 101, 241, 0.7);
    }

    50% {
        box-shadow: 0 0 20px 10px rgba(36, 101, 241, 0.7);
    }

    100% {
        box-shadow: 0 0 0 rgba(36, 101, 241, 0.7);
    }
}


/* Animación de gradiente */
.buttonGrad {
    margin: 5px;
    padding: 10px 20px;
    border: none;
    color: white;
    border-radius: 10px;

    font-weight: bold;
    font-size: 14px;
    font-family: sans-serif;

    background-image: linear-gradient(to right, #ffcc00 50%, #0072BC 50%);
    background-size: 200% 100%;
    background-position: right;
    transition: background-position 0.3s ease;
}

/* Estilo del botón al hacer hover */
.buttonGrad:hover {
    background-position: left;
}






/*******************************Pata Modals****************************************/
/*igualmente aplicar diseño general btnDisenio*/

.botonModal {
    background-color: #042354;
    /*color ejemplo*/
}

.botonModal.btn-primary {
    /* Anular los estilos de .btn-primary */
    border: initial;
    height: 43px !important;
    border-radius: 10px !important;
    margin-left: 10%;
    margin-right: 10%;
}