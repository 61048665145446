.contenedorTablaAGF {
    width: 95%;
    max-height: 90% !important;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.miTablaGF {
    cursor: pointer;
}

.seleccionableGF {
    width: fit-content;
}

.seleccionableGF-first {
    text-align: center;
}

.espacioTablaAGF {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    min-height: 550px;
}

.SubContenedorTablaAGF td,
.SubContenedorTablaAGF th {
    border-bottom: 1px solid #A7C0D2 !important;
    border-top: 1px solid #A7C0D2 !important;
    border-left: 0px;
    border-right: 0px;
    padding: 10px;
    overflow: auto;
    width: 100%;
    color: #042354;
    height: 10px !important;
}

.SubContenedorTablaAGF th {
    background-color: #EEF1F1;
    font-weight: bold;
    font-size: medium;

    /*esto es para que la barra se mantenga cuando buscas en un sin fin de rows*/
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
}

.SubContenedorTablaAGF tr td {
    background-color: white;
    height: 10% !important;
}

.SubContenedorTablaAGF table {
    border-collapse: collapse;
    letter-spacing: 1px;
    font-family: 'Fira Sans', sans-serif;
    font-size: 0.8rem;
    font-weight: bold;
    width: 100%;
    min-height: 40%;
    table-layout: fixed;
    text-align: justify;
    align-items: center;
    justify-content: center;
    overflow: scroll;
    color: #042354;
    border: 1px solid #A7C0D2;
    line-height: 2;

}


.checkboxAGF {
    width: 100%;
    text-align: center;
    overflow: hidden;
    font-size: medium;
    /* font-weight: bolder; */
}


.paginationAGF {
    display: flex;
    justify-content: center;
    padding-left: 0%;
    margin-bottom: 0%;
    list-style: none;
}

/*SCROLLBAR**/

.contenedorBarraAGF {
    width: 70%;
}

/**** FIN BARRA DE BUSQUEDA ****/



.btn-outline-successAGF .bi {
    display: flex;
    color: #6c757d;
}



.barraSuperiorAGF {
    display: flex;
    align-items: flex-start;
    justify-content: left;
    width: 100%;
    height: 10%;
    margin: 25px 25px 5px 25px;
}


.btnAniadirAGF {
    background-color: #0072BC;
}

.btnImportarAGF {
    background-color: #042354;
}

.btnHabilitarAGF {
    background-color: #042354;
}

.btnDeshabilitarAGF {
    background-color: #9E0520;

}

.contenedorBarraAGF {
    width: 70%;
    height: 50% !important;
}

.barraIntermediaAGF {
    display: flex;
    align-items: center;
    justify-content: right;
    width: 100%;
    height: 50%;
    margin-bottom: 2%;

    /* background-color: #0072BC; */
}




/**BARRA DE BUQEUDA FIN****/

.SubContenedorTablaAGF {
    height: 100%;
    /* background-color: #042354; */
}

.barraInferiorAGF {
    font-size: 1rem;
    /* background-color: #6c757d; */
}

.my-previous-buttonAGF,
.my-next-buttonAGF {
    color: #042354;

}

.my-page-classAGF {
    /* background-color: grey; */
    color: white;
    padding: 8px;
}

.my-page-link-classAGF {
    text-decoration: none;
    color: #042354;
}


.my-next-classAGF,
.my-previous-classAGF {
    color: white;
    padding: 8px;
    border-radius: 10px;
}

.my-previous-classAGF:hover,
.my-next-classAGF:hover {
    background-color: #042354;

}

.my-previous-buttonAGF:hover,
.my-next-buttonAGF:hover {
    color: white;
}

.my-page-classAGF:hover {
    border: 1px solid #042354;
    color: white;
    border-radius: 10px;
}

.botonAceptarAGF {
    background-color: #042354 !important;
}

.botonCancelarAGF {
    background-color: #9E0520 !important;
}

.tablaAGF {
    width: 100%;
    border-collapse: collapse;
    border-radius: 8px;
    /* Agrega bordes redondeados */
    overflow: hidden;
    /* Oculta cualquier contenido que se salga de la tabla */
    /*border: 2px solid purple;*/
}
.encontrLabel{
    margin-top: 1.5%;
    font-weight: bold;
}