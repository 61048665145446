.REAMMinicioPaginaAI {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    font-family: 'Fira Sans', sans-serif;
    font-size: 17px;
    color: #042354;
    background-color: white;
    margin: 1.5% 0% 1.5% 0%;
    width: 95%;
    min-height: 95%;
    padding-bottom: 10px;
    border: 1px solid black;
    border-radius: 5px;
    /* align-items: flex-end; */
}

.REAMMContenedorSuperior {

    width: 95%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* background-color: aliceblue; */
}

.REAMMContenedorBuscadoresSecundarios {
    width: 100%;
    height: 90%;
    /* background-color: #042354; */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

}

.REAMMContSecSup1,
.REAMMContSecSup2 {
    width: 50%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: left;
    text-align: center;
    /* background-color: bisque; */
}

.REAMMContSecSup1Sup{
    width: 95% !important;

}
.REAMMContSecSup1Nom,
.REAMMContSecSup1Box,
.REAMMContSecSup2Nom,
.REAMMContSecSup1Box {
    width: 50%;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 1rem;
    font-weight: bolder;
    color: #042354;

    /* background-color: #0423  54; */
}

.REAMMContSecSup2 {
    justify-content: right;
}


.REAMMContSecSup2Nom {
    width: 20%;
}

.REAMMContSecSup1 {
    margin-top: 15px;
}

.REAMMContenedorInferior {

    width: 95%;
    height: 90%;
    display: flex;
    flex-direction: column;
    /* background-color: aqua; */
}

.contenedorBarraREAMM {
    display: flex;
    align-items: flex-start;
    justify-content: left;
    width: 100%;
    height: 30%;
    margin-bottom: 10px;

    /* background-color: #042354; */
}



.REAMMbtnAniadir {
    background-color: #0072BC;
}

.REAMMinput{
    margin-left: 20px;
}

.REAMMinput-groupGC input {
    width: 60%;
}

.seleccionableGC {
    cursor: pointer;
}

.botonDiseñoREAMM {}

.codigoColGC{
    display: flex;
    color: purple;
    text-align: center !important;
    align-items: center !important;
}
.contenedorTablaCentradoREAMM .rt-tr-group {
    display: flex;
    align-items: center;
    justify-content: center !important;
  }

.REAMMContSecSup1Nom2{

    width: 50% !important;
    margin-left: 1%;
}

.REAMMContSecSup1Nom3{
    margin-left: 10%;
    width: 30%;
}
.mensajeEleccionRep{
    margin-left: 5px;
    display: flex;
    justify-content: left;
    margin-bottom: 2px;
    margin-top: 2px;
    font-size: 1.1rem;
}
.contDatosBusqREAMM{
    height: 85px;
    margin-bottom: 20px;
}
body.modal-open {
    overflow: hidden !important;
  }

.tablaCabeceLeft th{
    text-align: left !important;
}
.tablaCabeceLeft :first-child{
    text-align: center !important;
}