.btnAniadirPM {
    background-color: #0072BC;
}

.btnEliminarPM {
    background-color: #9E0520;
}

.contenedorTablaGPM {
    width: 95%;
    height: 100%;

    max-height: 90% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}