/*CONTENEDORES*/
.contenedorPrincipalREVC {
    display: flex;
    flex-direction: column;
    font-family: 'Fira Sans', sans-serif;
    color: #042354;
    margin: 1.5% 0% 1.5% 0%;
    width: 95%;
    min-height: 95%;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 20px;
    border: 1px solid black;
    padding: 2%;
    background-color: #fff;
    text-align: left;

    justify-content: left;
    align-items: left;
    margin-top: 2%;
}

.contenedorDatosSuperioresREVC {
    margin-bottom: 20px;
}

.contenedor-codigoREVC {
    display: flex;
    align-items: center;
}

.contenedor-descripcionREVC {
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.contenedor-evidenciaREVC {
    display: flex;
    align-items: center;
    margin-top: 2%;
}

.contenedor-guardarREVC {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 18%;
}

.contenedor-indicadoresREVC {
    height: 60%;
}

.contIndicREVC {
    border: 1px solid black;
    padding: 2%;
    background-color: #fff;
    border-radius: 5px;
    margin-top: 2px;
    width: 100%;
    border-top: 15px solid #042354;
}

.contenedor-botonesREVC {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 10%;
}

.contenedor-indicadores-rellenoREVC {
    width: 100%;
    height: 100%;
    padding-top: 20px !important;
    font-weight: bold;
    color: #0072BC;
    align-items: flex-start;
    display: flex;
    justify-content: center;
    margin-top: 0px !important;
}

.contDatosREVC {
    width: 95%;
    height: 5%;
}



.codigoREVC,
.descripcionREVC,
.evidenciaREVC {
    width: 20%;
    height: 20%;
}


.form-control1REVC {
    font-size: 1rem;
    background-color: #F2F7F9;
    height: calc(2rem + 2px);
}

.form-control2REVC {
    font-size: 1rem;
    height: calc(4rem + 2px);
    width: 100%;
}


.tituloIDsREVC {

    margin-top: -55px;

    width: 17%;

    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}





/*BOTONES*/

.botonesSuperioresREVC {
    display: flex;
    width: 20%;
    justify-content: flex-end;
}



.btnAniadirREVC {
    background-color: #0072BC;
}

.btnEliminarREVC {
    background-color: #9E0520;
}

.btnGuardarREVC {
    background-color: #ADADAD;
}


.botonAceptarREVC {
    background-color: #042354;
    width: 120px;
    height: 35px;
    font-size: 80%;
    margin-left: 10%;
    font-weight: bold;
}

.botonCancelarREVC {
    background-color: #9E0520;
    width: 120px;
    height: 35px;
    font-size: 80%;
    margin-right: 10%;
    font-weight: bold;
}