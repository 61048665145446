.espacioTablaREOE {
    width: 95%;
}

.espacioTablaREOE p {
    font-weight: bold;
}

.miTabla {
    cursor: pointer;
}


.contenedorTablaREOE {
    width: 95%;
    max-height: 90% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inputDatosREOE {
    width: 40%;
}

.modalAnchoPersonalizadoREOE {
    align-items: center;
    justify-content: center;
}

.espacioSupModalREOE {
    width: 100%;
    text-align: right;
}

.botonCerrarREOE {
    background-color: white;
    border: 0px;
}

.seleccionableREOE {
    width: fit-content;
}

.espacioTablaREOE {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.espacioTablaGO p {
    font-weight: bold;
}

.checkboxREOE {
    width: 100%;
    text-align: center;
    overflow: hidden;
    font-size: medium;
    /* font-weight: bolder; */
}


.paginationREOE {
    display: flex;
    justify-content: center;
    padding-left: 0%;
    margin-bottom: 0%;
    list-style: none;
}

/*SCROLLBAR**/

.contenedorBarraREOE {
    width: 70%;

}

/**** FIN BARRA DE BUSQUEDA ****/


.barraSuperiorREOE {
    display: flex;
    align-items: flex-start;
    justify-content: right;
    width: 100%;
    height: 10%;
    margin-bottom: 20px;
}

.input-groupGM {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 15px 15px 15px 0px;
}

.form-controlRAM {
    display: flex;
    height: 37px;
    /* Cambia el valor según tu necesidad */
    width: 100%;
    max-width: 1000px;
    font-size: 14px;
    /* Cambia el tamaño de fuente según tu necesidad */
    border: 1px solid #ccc;
    border-radius: 4px;
}

.input-group-appendGM .m-0 {
    width: 100%;
    height: 37px;
}

.botonesSuperioresREOE {
    display: flex;
    align-items: center;
    justify-content: right;
    text-align: right;
    height: 100%;

}

.labelREOE {
    width: 50%;
}


.btnAniadirREOE button {
    background-color: #0072BC;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: small;
    border: 0px;
    border-radius: 10px;
    width: 115px;
    height: 35px;
}



.barraIntermediaREOE {
    display: flex;
    align-items: center;
    justify-content: right;
    width: 100%;
    height: 50%;
    margin-bottom: 2%;

    /* background-color: #0072BC; */
}

.btnHabilitarBI {
    background-color: #0072BC;
}


.btnBuscarBI {
    background-color: #042354;
}


/**BARRA DE BUQEUDA FIN****/


.barraInferiorREOE {
    font-size: 1rem;
    /* background-color: #6c757d; */
}

.botonAceptarREOE {
    background-color: #042354;
    width: 120px;
    height: 35px;
    font-size: 80%;
    margin-left: 10%;
    font-weight: bold;
}

.botonCerrarREOE {
    align-items: end;
}

.botonCancelarREOE {
    background-color: #9E0520;
    width: 120px;
    height: 35px;
    font-size: 80%;
    margin-right: 10%;
    font-weight: bold;
}

.contenedorGO {
    display: flex;
    justify-content: space-between;
}

.modalAnchoPersonalizadoGO .modal-dialogGO {
    max-width: 40%;
    /* Ajusta el valor según tus necesidades */
}


.derechaGO {
    text-align: left;
    width: 30%;
}

.izquierdaGO {
    width: 70%;
    text-align: left;
}

.REAMComboBox {
    /* width: -100%; */

    display: flex;
    margin: 5px 15px 15px 15px;
    height: 40px;
    border-radius: 5px;
    font-size: 14px;

}

.REAMComboBox select {
    /* width: -100%; */
    border-radius: 5px;
    color: #042354;
    /* para hacer los bordes redondos */
    border: 1px solid #ccc;
    padding: 5px;
    /* para establecer el color del borde */
}

.contenedorBarraGM {
    width: 70%;
}

.contBuscarREBI {
    margin-top: 10px;
}

.mensajeEleccionRepBI{
    width: 100%;
    margin-left: 10px;
    display: flex;
    justify-content: left;
    margin-bottom: 2px;
    margin-top: 8px;
    font-size: 1.1rem;
}

.REAMMContenedorBuscadoresSecundariosBI {
    width: 100%;
    /* background-color: #042354; */
    align-items: center;
    justify-content: center;
    text-align: center;

}
.REAMMContenedorSuperiorBI {

    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* background-color: aliceblue; */
    margin-top: 20px;
    margin-bottom: 5px;
}

.REAMMContSecSup1BI,
.REAMMContSecSup2BI {
    width: 50%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: left;
    text-align: center;
    /* background-color: bisque; */
}
.REAMMContSecSup1NomBI,
.REAMMContSecSup1BoxBI,
.REAMMContSecSup2NomBI,
.REAMMContSecSup1BoxBI2 {
    width: 50%;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 1rem;
    font-weight: bolder;
    color: #042354;

    /* background-color: #0423  54; */
}
.REAMMContSecSup1BoxBI2{
    width: 83% !important;
}
.REAMMContSecSup1NomBI,
.REAMMContSecSup2NomBI {
    width: 20%;
}

.REAMMContSecSup1BI {
    margin-top: 15px;
}

.REAMMContSecSup1BI3{
    width: 90% !important;
}

.REAMMContSecSup1Nom2BI,
.REAMMContSecSup1Nom3BI{

    width: 230px  !important;
    margin-left: 5px;
}

.REAMMContSecSup1Nom3BI{
    width: 230px !important;
}

.REAMMinput{
    margin-left: 20px;
    width: 100% !important;
}

.formBuscador{
    width: 100% !important;
}
