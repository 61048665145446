.contenedorTablaEC {
    width: 95%;
    max-height: 90% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.miTabla {
    cursor: pointer;
}

.barraSuperiorC {
    display: flex;
    align-items: flex-start;
    justify-content: left;
    width: 100%;
    margin: 25px 25px 5px 25px;
}
.contenedor-indicadores-rellenoREAC {
    width: 100%;
    height: 100%;
    padding-top: 2%;
    font-weight: bold;
    color: #0072BC;
    align-items: flex-start;
    display: flex;
    justify-content: center;
    font-size: 16px;
}

.botonesSupIzquierdaC {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 40%;
    height: 2%;
    margin-top: 0.5%;
    margin-right: 59%;
    
}




.btnAniadirC {
    background-color: #0072BC;
}

.btnDeshabilitarC {
    background-color: #9E0520;
}

.botonCancelarDC {
    background-color: #9E0520;
}

.btnImportarC button {
    background-color: #042354;
    width: 120px;
    height: 40px;
    justify-content: center;
    color: white;
    font-size: small;
    border: 0px;
    border-radius: 10px;
}

.btnParametrosC button {
    background-color: #042354;
    justify-content: flex-start;
    color: white;
    border: 0px;
    border-radius: 10px;
    width: 120px;
    height: 40px;
    font-weight: bold;
    font-size: 14px;
    font-family: sans-serif;
}

.contenedorBarraC {
    width: 100%;
    height: 50% !important;
}

.contAlignCenter{
    display: flex;
    align-items: center;
    justify-content: center;
}


.checkboxREC {
    width: 100%;
    text-align: center;
    overflow: hidden;
    font-size: medium;
    /* font-weight: bolder; */
}
.encontrLabel{
    margin-top: 1.5%;
    font-weight: bold;
}