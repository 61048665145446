/*CONTENEDORES*/
.contenedorPrincipalRFVDE {
    display: flex;
    flex-direction: column;
    font-family: 'Fira Sans', sans-serif;
    color: #042354;
    margin: 1.5% 0% 1.5% 0%;
    width: 95%;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 20px;
    border: 1px solid black;
    padding: 2%;
    background-color: #fff;
    text-align: left;

    justify-content: left;
    align-items: left;
    margin-top: 2%;
}

.contenedorDivisionRFVDE {
    display: flex;
}

.contenedorDivisionRFVDE4 {
    display: flex;
    margin-bottom: 15px;
}

.contenedorDivisionRFVDE2 {
    display: flex;
    margin-top: 30px;
    width: 100%;
}
.derechaRFVDE {
    flex: 1;
}

.izquierdaRFVDE {
    flex: 1;
}


.contenedorDatosSuperioresRFVDE {
    height: 45%;
}

.contenedor-codigoRFVDE {
    display: flex;
    align-items: center;
}

.contenedor-descripcionRFVDE {
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.contenedor-evidenciaRFVDE {
    display: flex;
    align-items: center;
    margin-top: 2%;
}

.contenedor-guardarRFVDE {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 18%;
}

.contenedor-indicadoresRFVDE {
    height: 100%;
}

.contIndicRFVDE {
    border: 1px solid black;
    padding: 2%;
    background-color: #fff;
    border-radius: 5px;
    margin-top: 2px;
    width: 100%;
    border-top: 15px solid #042354;
    margin-bottom: 50px;
}

.contenedor-botonesRFVDE {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 10%;
}

.contenedor-indicadores-rellenoRFVDE {
    width: 100%;
    height: 100%;
    padding-top: 2%;
    font-weight: bold;
    color: #0072BC;
    align-items: flex-start;
    display: flex;
    justify-content: center;
}

.contDatosRFVDE {
    width: 95%;
    height: 5%;
}



.codigoRFVDE,
.descripcionRFVDE,
.evidenciaRFVDE {
    width: 40%;
}

.inputRFVDE {
    padding: 0 10px;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 1rem;
    color: #042354;
}

.form-control1RFVDE {
    font-size: 1rem;
    background-color: #F2F7F9;
    height: calc(2rem + 2px);
}

.form-control2RFVDE {
    font-size: 1rem;
    height: calc(4rem + 2px);
    width: 100%;
}



.tituloIDsRFVDE {
    margin-top: -55px !important;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}





/*BOTONES*/

.botonesSuperioresRFVDE {
    display: flex;
    width: 20%;
    justify-content: flex-end;
}

.btnGuardarRFVDE {
    background-color: #ADADAD;
}

.btnAniadirRFVDE {
    background-color: #0072BC;
}

.btnEliminarRFVDE {
    background-color: #9E0520;
}

.botonAceptarRFVDE {
    background-color: #042354;
    width: 120px;
    height: 35px;
    font-size: 80%;
    margin-left: 10%;
    font-weight: bold;
}

.botonCancelarRFVDE {
    background-color: #9E0520;
    width: 120px;
    height: 35px;
    font-size: 80%;
    margin-right: 10%;
    font-weight: bold;
}

.inputEsp {
    font-size: 15px !important;
    padding: 15px 10px 15px 10px !important;
}

.btnMuestras{
    align-items: start;
}

.btnMuestras{
    justify-content: left;
    width:100%;
}