.inicioPaginaAC {
    display: flex;
    flex-direction: column;
    font-family: 'Fira Sans', sans-serif;
    color: #042354;

    width: 95%;
    border: 1px solid black;
    border-radius: 5px;
    align-items: center;
    margin-top: 2%;
    /*max-height: 100%;
    overflow: hidden;
    background-color: white;*/

    background-image: url("../images/fondoDetalleCuenta.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.inputEspecialAC {
    border: none;
    color: red;
    height: 15px;
    font-size: 13px !important;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
}


.contbot {
    width: 90%;
    margin-bottom: 20px;
}


.contenedorAC {
    display: flex;
    justify-content: space-between;
    flex: 1;
    text-align: left;
    padding-left: 2%;
    padding-bottom: 1%;
    padding-right: 2%;
}

.alineacionAC {
    width: 40%;
}

.nombreCuentaAC {
    padding-left: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
}

.contDatosAC {
    width: 90%;
    height: 100%;
}

.contfotoPerfilAC {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fotoPerfilAC {
    height: 200px;
    width: 200px;
    min-height: 80%;
    background-color: #667D8D;
    border-radius: 100%;
}


.contPerfilAC {
    border: 1px solid black;
    height: 90%;
    background-color: #fff;
    border-radius: 5px;
    margin: 0% !important;
    border-radius: 5px;
    border-top: 15px solid #042354;
    background-color: #fff;
    padding: 20px;
    position: relative;
}

.tituloAAC {
    width: 50% !important;
    margin-top: -45px !important;

}

.contTituloAC {
    margin-bottom: 0px !important;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}





.inputAC {
    background-color: white;
    color: #042354;
    border: 1px solid black;

    border-radius: 5px;
    width: 100%;
    height: 30px;
    border-width: 1px;
    font-size: 15px;
    padding: 15px 10px 15px 10px;
}

.botonAceptarAC {
    background-color: #042354;
    width: 120px;
    height: 35px;
    font-size: 80%;
    margin-left: 10%;
    font-weight: bold;
}

.botonCancelarAC {
    background-color: #9E0520;
    width: 120px;
    height: 35px;
    font-size: 80%;
    margin-right: 10%;
    font-weight: bold;
}

.modal-body p {
    font-size: 1.2rem;
    text-align: center;
    font-weight: bold
}

.modal-footer {
    justify-content: center;
}

.modal {
    font-family: 'Fira Sans', sans-serif;
    margin-top: 14%;
}

.label {
    font-size: 18px;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
}