/*Estilo general*/
.barraLateral {
    background-color: #042354;
    height: 100%;
    /* Full-height: remove this if you want "auto" height*/
    position: fixed;
    /* Fixed Sidebar (stay in place on scroll) */
    z-index: 1;
    /* Stay on top */
    top: 0;
    /* Stay at the top */
    overflow-x: hidden;
    /* Disable horizontal scroll */
}

.main {
    padding-right: 0px;
    padding-left: 0px;
    background-color: #F2F3F3;
}


.cuadro {
    margin-right: 20px;
    align-items: center;
    justify-content: center;
}

.cuadro .nombre p {
    font-size: 1.0rem;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

}

.cuadro .tiempo {
    align-items: center;
    justify-content: center;
    font-size: 0.7rem;

}

.perfil {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: large;
}

.titulo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: white;
    border: 0px;
}

/*Parte central del menu*/
.menu li a i {
    font-size: 2.0rem;
}

.menu li a {
    color: white;
    font-size: large;
    font-family: 'Fira Sans', sans-serif;
}

.menu li:hover {
    width: 100%;
    background-color: #003C7A;
    color: white;
}

.menu li:hover a {
    color: white;
}

/*Area de trabajo*/
.subContenedorMiPerfil {
    width: 100%;
    height: 100%;
}

.encabezadoMP {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 10% !important;
    width: 100%;
    border-bottom: 2px solid #fbb917;
    margin-top: 3%;

}




.areatrabajoMiPerfil {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    height: 82%;
    margin-top: 2%;
}

.mainAdminMiPerfil {
    width: 100%;
    height: 100%;
}

.containerMP {
    position: absolute;
    height: 100%;
    background-image: url("../images/fondoMiPerfil3.png");
    background-repeat: no-repeat;
    background-size: cover;
    font-family: sans-serif !important;
    width: 100%;
    overflow: hidden;
}

.contPerf {
    width: 100%;
    height: 100%;
}

.tituloMiPe,
.flechaMiPe {
    color: #042354 !important;
}