/*CONTENEDORES*/
.contenedorPrincipalREAC {
    display: flex;
    flex-direction: column;
    font-family: 'Fira Sans', sans-serif;
    color: #042354;
    margin: 1.5% 0% 1.5% 0%;
    width: 95%;
    border: 1px solid black;
    border-radius: 5px;

    border: 1px solid black;
    padding: 2%;
    background-color: #fff;
    text-align: left;
    font-size: 20px;

    justify-content: left;
    align-items: left;
    margin-top: 2%;
    min-height: 95%;
}

.contenedorDatosSuperioresREAC {
    margin-bottom: 40px;
}

.contenedor-codigoREAC {
    display: flex;
    align-items: center;
}

.contenedor-evidenciaREAC {
    display: flex;
    align-items: center;
}

.contenedor-descripcionREAC {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 20px;
}

.contenedor-guardarREAC {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 7%;
}

.contenedor-indicadoresREAC {
    height: 60%;
}

.contIndicREAC {
    border: 1px solid black;
    padding: 2%;
    background-color: #fff;
    border-radius: 5px;
    margin-top: 2px;
    width: 100%;
    border-top: 15px solid #042354;
    margin-bottom: 20px;
}

.contenedor-botonesREAC {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 14%;
}

.contenedor-indicadores-rellenoREAC {
    width: 100%;
    height: 100%;
    padding-top: 2%;
    font-weight: bold;
    color: #0072BC;
    align-items: flex-start;
    display: flex;
    justify-content: center;
    font-size: 16px;
}

.contDatosREAC {
    width: 95%;
    height: 5%;
}

.filaREAC {
    text-align: center;
}

.codigoREAC,
.descripcionREAC,
.evidenciaREAC {
    width: 10%;
    height: 7%;
}

.inputREAC {
    padding: 0 10px;
    background-color: white;
    color: #042354;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 15px;
}

.form-control1REAC {
    font-size: 15px;
    height: calc(2rem + 2px);
}

.form-control2REAC {
    font-size: 15px;
    height: calc(4rem + 2px);
    text-align: start;
}


.tituloIDsREAC {
    background-color: #fff;
    padding: 5px;
    border-radius: 5px;
    margin-top: -55px;
    border: 1px solid black;
    width: 17%;
    height: 15%;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-family: 'Fira Sans', sans-serif;
}

/*TABLA*/
.SeleccionREAC {
    width: 12%;
}

.CodigoREAC {
    width: 18%;
}

.IndicadorREAC {
    width: 70%;
}

.ColumnaREAC {
    background-color: #F2F3F3;
}



/*BOTONES*/

.botonesSuperioresREAC {
    display: flex;
    justify-content: flex-end;
}



.btnAniadirREAC {
    background-color: #0072BC;

}

.btnEliminarREAC {
    background-color: #9E0520;
}

.btnGuardarREAC {
    background-color: #ADADAD;
}


.botonAceptarREAC {
    background-color: #042354;
}

.botonCancelarREAC {
    background-color: #9E0520;
}