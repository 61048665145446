/*CONTENEDORES*/
.contenedorPrincipalRECPM {
    display: flex;
    flex-direction: column;
    font-family: 'Fira Sans', sans-serif;
    color: #042354;
    margin: 1.5% 0% 1.5% 0%;
    width: 95%;
    border: 1px solid black;
    border-radius: 5px;

    border: 1px solid black;
    padding: 2%;
    background-color: #fff;
    text-align: left;
    font-size: 20px;

    justify-content: left;
    align-items: left;
    margin-top: 2%;

    min-height: 95%;
}

.contenedorDatosSuperioresRECPM {
    margin-bottom: 40px;
}

.contenedor-codigoRECPM {
    display: flex;
    align-items: center;
}

.contenedor-evidenciaRECPM {
    display: flex;
    align-items: center;
}

.contenedor-descripcionRECPM {
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.contenedor-guardarRECPM {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 7%;
}

.contenedor-indicadoresRECPM {
    height: 60%;
}

.contIndicRECPM {
    border: 1px solid black;
    padding: 2%;
    background-color: #fff;
    border-radius: 5px;
    margin-top: 2px;
    width: 100%;
    border-top: 15px solid #042354;
    margin-bottom: 20px;
}

.contenedor-botonesRECPM {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 20%;
    margin-bottom: 20px;
}

.contenedor-indicadores-rellenoRECPM {
    width: 100%;
    height: 100%;
    padding-top: 2%;
    padding-bottom: 2%;
    font-weight: bold;
    color: #0072BC;
    align-items: flex-start;
    display: flex;
    justify-content: center;
    font-size: 16px;
}

.contDatosRECPM {
    width: 95%;
    height: 5%;
}

.filaRECPM {
    text-align: center;
}

.codigoRECPM,
.descripcionRECPM,
.evidenciaRECPM {
    width: 15%;
    color: #042354;
}



.form-control1RECPM {
    font-size: 15px;
    height: calc(2rem + 2px);
}

.form-control2RECPM {
    font-size: 15px;
    height: calc(4rem + 2px);
    text-align: start;
}

.tituloIDsRECPM {
    background-color: #fff;
    padding: 5px;
    border-radius: 5px;
    margin-top: -45px;
    border: 1px solid black;
    width: 17%;
    height: 15%;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-family: 'Fira Sans', sans-serif;
}

/*TABLA*/
.SeleccionRECPM {
    width: 12%;
}

.CodigoRECPM {
    width: 18%;
}

.IndicadorRECPM {
    width: 70%;
}

.ColumnaRECPM {
    background-color: #F2F3F3;
}



/*BOTONES*/

.botonesSuperioresRECPM {
    display: flex;
    justify-content: flex-end;
}


.btnAniadirRECPM {
    background-color: #0072BC;
}

.btnEliminarRECPM {
    background-color: #9E0520;
}

.btnGuardarRECPM {
    background-color: #ADADAD;
}


.botonAceptarRECPM {
    background-color: #042354;
    width: 120px;
    height: 35px;
    font-size: 80%;
    margin-left: 10%;
    font-weight: bold;
}

.botonCancelarRECPM {
    background-color: #9E0520;
    width: 120px;
    height: 35px;
    font-size: 80%;
    margin-right: 10%;
    font-weight: bold;
}
.contTablaCPM{
    align-items: start !important;
    height: auto !important;
}