.REGRContenedorPrincipal {

    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    /* background-color: pink; */

}

.custom-modal {
    position: relative;
    z-index: 1100;
    display: inline-block !important;
}

.REGRContenedorLineas {

    width: 90%;
    height: 90%;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: left;

    /*background-color: white;*/
}

.REGRContendorTabla {
    width: 100%;
    height: 100%;
    display: flex;
    color: #042354;
    flex-direction: column;
    align-items: center;
    justify-content: left;

    /* background-color: black; */
}

.REGRLineaOpcion {
    width: 100%;
    height: 95%;
    display: flex;
    align-items: center;
    text-align: center;
    text-decoration: underline;
    justify-content: center;
    margin: 10px 10px;

    color: white;
    font-weight: bold;
    border-radius: 8px;

}

.REGRContenedorCancelar {
    width: 100%;
    height: 100%;
    display: flex;
    /* background-color: #003C7A; */
    align-items: flex-end;
    justify-content: right;
}

.REGRbotonCancelar {
    background-color: transparent;
    border: 1px solid transparent;
}

.botonCircCancelar {
    font-size: 20px;
}

.REGRContenedorEspacioGR {
    width: 75%;
    height: 100%;
    display: flex;
    /* background-color: #003C7A; */
    align-items: center;
    justify-content: center;
    /* background-color: wheat; */
    /* font-size: small; */
}

.REGRInicio {
    display: flex;
    height: 50%;
}

.REGRFinal {
    display: flex;
    height: 50%;
}


.REGRContenedorExportar {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
    align-content: center;
    text-align: right;
    /* background-color: antiquewhite; */
}

.botonExportar {
    background-color: #042354;
}

.botonExportar.btn-primary {
    /* Anular los estilos de .btn-primary */
    border: initial;
    height: 40px !important;
    border-radius: 10px !important;
}

.REGRCurso {
    display: flex;
    height: 50%;
}

.REGRTitulos {
    font-weight: bold;
    width: 110px;
    margin-left: 30px;

}

.REGRComboBox {
    display: flex;
}

.REGRComboBox select {
    color: #042354;
    border-radius: 5px;
    padding: 5px;
    min-width: 200px;
    max-width: 300px;
}

.opciones-sup {
    width: 100%;
    height: 50%;
    /*background-color: palegreen;*/
    display: flex;
    margin-bottom: 12px;

}

.referenciaResGen {
    background-image: url("../images/resulGen.png");
    margin: 0px 0px 15px 6px;
}



.referenciaIndicadores {
    background-image: url("../images/indicador.png");
    margin: 0px 0px 15px 5px;
}

.opciones-inf {
    width: 100%;
    height: 50%;
    /*background-color: green;*/
    display: flex;

}

.referenciaSegMuestra,
.referenciaPlanMejora,
.referenciaResGen,
.referenciaObjEduc,
.referenciaResMuest,
.referenciaIndicadores {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-decoration: none;

    border-radius: 8px;
    color: white;
    font-size: 20px;
    font-weight: bold;

    background-repeat: no-repeat;
    background-size: cover;
    filter: grayscale(60%);
    overflow: hidden;
}

.referenciaSegMuestra {
    background-image: url("../images/seguiMuestras.png");
    margin: 0px 0px 13px 5px;
}

.referenciaPlanMejora {
    background-image: url("../images/planMejora.png");
    margin: 0px 0px 13px 5px;
}

.referenciaObjEduc {
    background-image: url("../images/objetivosEdu.png");
    margin: 0px 0px 13px 4px;
}

.referenciaResMuest {
    background-image: url("../images/resultMuestra.png");
    margin: 0px 0px 13px 6px;
}


.referenciaSegMuestra:hover,
.referenciaIndicadores:hover,
.referenciaPlanMejora:hover,
.referenciaObjEduc:hover,
.referenciaResMuest:hover,
.referenciaResGen:hover {
    filter: brightness(1);
    /* Brillo reducido al hacer hover */
    color: #0072BC;
}

.referenciaSegMuestra:hover .textoHiper,
.referenciaObjEduc:hover .textoHiper,
.referenciaResMuest:hover .textoHiper {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 1% 4% 1% 4%;
    display: flex;
    justify-content: center;
    /* Centrar horizontalmente */
    align-items: center;
    /* Centrar verticalmente */
}

.referenciaPlanMejora:hover .textoHiper,
.referenciaIndicadores:hover .textoHiper,
.referenciaResGen:hover .textoHiper {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 1% 4% 1% 4%;
    display: flex;
    justify-content: center;
    /* Centrar horizontalmente */
    align-items: center;
    /* Centrar verticalmente */
}

.referenciaResGen:hover {
    background-image: url("../images/resulGen2.png");
}

.referenciaObjEduc:hover {
    background-image: url("../images/objetivosEdu2.png");
}

.referenciaIndicadores:hover {
    background-image: url("../images/indicador2.png");
}

.referenciaResMuest:hover {
    background-image: url("../images/resultMuestra2.png");
}

.referenciaSegMuestra:hover {
    background-image: url("../images/seguiMuestras2.png");
}

.referenciaPlanMejora:hover {
    background-image: url("../images/planMejora2.png");
}

.tituloEspecial {
    width: 100%;
    text-transform: uppercase;
    margin-bottom: 20px;
    color: #042354;
    font-weight: bold;
    font-size: 18px;
}

.desdeDate {
    margin-bottom: 5px;
}

.REGRRadioButton input {
    margin-right: 6px;
    margin-left: 20px;
}

.custom-date-picker {

    padding: 3px;
    font-size: 16px;

}

.react-datepicker-wrapper {
    width: auto !important;
}

.REGREncabezadoIndicadores {
    margin-left: 40px;
}

.referenciaSegMuestra .textoHiper,
.referenciaObjEduc .textoHiper,
.referenciaResMuest .textoHiper {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 1% 4% 1% 4%;
    color: #0072BC;
    display: flex;
    justify-content: center;
    /* Centrar horizontalmente */
    align-items: center;
    /* Centrar verticalmente */
}

.referenciaIndicadores .textoHiper,
.referenciaPlanMejora .textoHiper,
.referenciaResGen .textoHiper {
    background-color: rgba(255, 255, 255, 0.8);
    color: #0072BC;
    padding: 1% 4% 1% 4%;
    display: flex;
    justify-content: center;
    /* Centrar horizontalmente */
    align-items: center;
    /* Centrar verticalmente */
}

.titEspeIndic {
    width: 120px !important;
}

.REGRModalError {

    text-align: left;
    display: flex;
    justify-content: left;
    align-items: start;
    justify-content: left;

}
.dropdown button {
    background-color: #0072BC !important;
    min-width: 70px;
    width: 160px;
}