.fechaSeleccion {
    margin: 5px;
}

.miTabla {
    cursor: pointer;
}

.inputEspecialAGCA {
    border: none;
    color: red;
}

.inputAGCA {
    width: 80% !important;
}
.inputAGCA2 {
    width: 50% !important;
}
.inputAGCA3 {
    width: 80% !important;
}
.contenedorTablaAGCA {
    width: 95%;
    max-height: 90% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inputDatosAGCA {
    width: 40%;
}

.modalAnchoPersonalizadoAGCA {
    align-items: center;
    justify-content: center;
}

.modalAnchoPersonalizadoAGCA .modal-dialog {
    max-width: 600px;
    /* Ancho máximo deseado del modal */
    margin: 0 auto;
    /* Centrar horizontalmente el modal */

}

.espacioSupModalAGCA {
    width: 100%;
    text-align: right;
}

.botonCerrarAGCA {
    background-color: white;
    border: 0px;
}

.seleccionableAGCA {
    width: fit-content;
}

.espacioTablaAGCA {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1.5%;
    margin-bottom: 1%;
}

.espacioTablaAGCA p {
    font-weight: bold;
}

.checkboxAGCA {
    width: 100%;
    text-align: center;
    overflow: hidden;
    font-size: medium;
    /* font-weight: bolder; */
}


.paginationAGCA {
    display: flex;
    justify-content: center;
    padding-left: 0%;
    margin-bottom: 0%;
    list-style: none;
}

/*SCROLLBAR**/

.contenedorBarraAGCA {
    width: 100%;
}

/**** FIN BARRA DE BUSQUEDA ****/

.input-groupAGCA {
    display: flex;
    flex-direction: row;
    width: 90%;
}

.input-group-appendAGCA .m-0 {
    width: 100%;
    height: 37px;
}

.btn-outline-successAGCA .bi {
    display: flex;
    color: #6c757d;
}

.form-controlAGCA {
    display: flex;
    height: 37px;
    /* Cambia el valor según tu necesidad */
    width: 850px;
    max-width: 1000;
    font-size: 14px;
    /* Cambia el tamaño de fuente según tu necesidad */
    border: 1px solid #ccc;
    border-radius: 4px;
    padding-left: 10px;
}

.form-controlAGCA::placeholder {
    color: #ccc;
}


.barraSuperiorAGCA {
    display: flex;
    align-items: flex-start;
    justify-content: right;
    width: 100%;
    margin: 25px 25px 5px 25px;
}

.barraBotSup {
    margin-top: 24px;
}



.btnAniadirAGCA {
    background-color: #0072BC;
}



.barraIntermediaAGCA {
    display: flex;
    align-items: center;
    justify-content: right;
    width: 100%;
    height: 50%;
    margin-bottom: 2%;

    /* background-color: #0072BC; */
}


.btnDeshabilitarAGCA {
    background-color: #9E0520;
}


/**BARRA DE BUQEUDA FIN****/


.barraInferiorAGCA {
    font-size: 1rem;
    /* background-color: #6c757d; */
}

.botonAceptarrAGCA {
    background-color: #042354;
}

.botonAceptarCicCreadoAGCA {
    background-color: #042354;
    width: 120px;
    height: 35px;
    font-size: 80%;
    margin-left: 10%;
    font-weight: bold;
    border: 0;
    padding: 0%;
    margin: 0;
    text-align: center;
    align-items: center;
}


.modalCicloCreadoAGCA {
    justify-content: center;
    align-items: center;
    text-align: center;
}

.botonCerrarAGCA {
    align-items: end;
}

.botonCancelarAGCA {
    background-color: #9E0520;
}

.contenedorAGCA {
    display: flex;
    justify-content: space-between;
}

.modalAnchoPersonalizadoAGCA .modal-dialogAGCA {
    max-width: 40%;
    /* Ajusta el valor según tus necesidades */
}


.derechaAGCA {
    text-align: left;
    width: 150px;
}

.izquierdaAGCA {
    text-align: left;
}
.inputEspecialREOE2{
    margin-top: 10px;
    margin-left: 25px;
    background-color: transparent !important;
    width: 100%;
}
.contenedorInferiorAGCA {
    display: flex;
    justify-content: center;
    /* Centrar horizontalmente */
    align-items: center;
    /* Centrar verticalmente */
}

.contenedorAGCA {
    margin-left: 25px;
}

.formDescr {
    height: 115px !important;
}

.formBuscCiclo{
    width: 80% !important;
    border: 1px solid #ccc !important;
}

.formBuscCicloFecha{
    margin-left: 20px;
    width: 130px;
    border: 1px solid #ccc !important;
    font-size: 15px !important;
}
.botonLupa{
    margin-left: 20px !important;
    border: 1px solid #0072BC !important;
    color: white;
    background-color: #057ccc81;
}






