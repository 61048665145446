.tablaF {
    border-collapse: collapse;
    letter-spacing: 1.5px;
    font-family: 'Fira Sans', sans-serif;
    font-size: 0.8rem;
    table-layout: fixed;
    align-items: center;
    justify-content: center;
    border: 1 solid #A7C0D2;
    overflow: hidden;
    line-height: 2;
    width: 100%;
    background-color: #A7C0D2;
    border-radius: 10px !important;
    /* Otros estilos... */
    border-radius: 95px;
}

.tablaF th {
    font-weight: bold;
    background-color: #adc3e6;
    text-transform: uppercase;
    color: white;
    vertical-align: middle;
    font-size: 14px;
}

.tablaF tr {
    border-bottom: 1px solid #A7C0D2 !important;
    border-top: 1px solid #A7C0D2 !important;
    border-left: 1px solid #A7C0D2 !important;
    border-right: 1px solid #A7C0D2 !important;
    overflow: auto;
    color: #042354;
    font-weight: normal;
    height: 40px !important;
    font-size: 13px;
}

.tablaF tr td {
    background-color: white;
    text-align: left;
    vertical-align: middle;
    padding-right: 10px;
}


.tablaF td:nth-child(2) {
    /*font-weight: bold;*/
}


.tablaF th:first-child {
    text-align: center;
}


.tablaF .estado {
    font-weight: bold;
}

.tablaF .elestado-activo {
    background-color: #a7f3d0;
    color: #1d8b6a;
    display: flex;
    align-items: center;
    /* Centrado vertical */
    justify-content: center;
    /* Centrado horizontal */
    margin-top: 5px;
    margin-bottom: 5px;

    font-size: 80%;
    text-transform: uppercase;
    font-weight: bold;
    padding: 8px 12px;
    border-radius: 30px;
    width: 50%;
    height: 30px;
}

.tablaF .elestado-inactivo {
    display: flex;
    align-items: center;
    /* Centrado vertical */
    justify-content: center;
    /* Centrado horizontal */
    margin-top: 5px;
    margin-bottom: 5px;

    font-size: 80%;
    text-transform: uppercase;
    font-weight: bold;
    padding: 8px 12px;
    border-radius: 30px;
    width: 50%;
    height: 30px;
    background-color: #fecaca;
    color: #b91e1e;
}

.table-rowF:hover {
    background-color: #eaf0f6;
}

.table-rowF.selected {
    background-color: #cfe8fc;
}

/*Contenedor de la tabla div debería ser así*/
.contenedorTablaF {
    /*width: "100%", height: "480px"*/
    width: 100%;
    height: 100%;
    margin-top: 20px;
    margin-bottom: 10px;
}

.SeleccionCol {
    width: 12%;
    text-align: center;
}

.tablaF td:first-child {
    text-align: center;
}

ul.react-tabs__tab-list{
    display: flex;
    border-bottom: 0;
    margin: 0px 0px 0px 8px;
}

div.contDatosDMM1 div.react-tabs ul.react-tabs__tab-list li.tabs{
    margin: 0px 0px 0px 12px;
    height: 30px;
    border: none;
    border-radius: 5px 5px 0px 0px;
    list-style: none;
    padding: 0.6em 1.2em 0.3em;
    display: flex;
    align-items: center;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
}

div.contDatosMM div.react-tabs ul.react-tabs__tab-list li.tabs{
    margin: 0px 0px 0px 12px;
    height: 30px;
    border: none;
    border-radius: 5px 5px 0px 0px;
    list-style: none;
    padding: 0.6em 1.2em 0.3em;
    display: flex;
    align-items: center;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
}

.indicFalla{
    padding: 5px 10px;
    margin: 0px 10px;
    border-radius: 15px;
    background-color: #042354;
    color: white;
    display: inline-block;
    min-width: 60px;
    text-align: center;
}