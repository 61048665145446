.contenedorDatosGen {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.contenedorGen {
    border: 1px solid #042354;
    padding: 20px;
    padding-left: 50px;
    padding-right: 50px;
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    border-top: 15px solid #042354;
}



.tituloGen {
    font-size: 20px;
    background-color: #fff;
    padding: 5px;
    border-radius: 5px;
    margin-top: -9%;
    border: 1px solid black;
    width: 70%;
    height: 60%;
    text-align: center;
    font-family: "Montserrat", sans-serif !important;
}


.datos-containerGen {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    width: 100%;
}

.datosDivGen {
    width: 100%;
}






.labelGen {
    font-size: 16px;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
}

.inputGen {
    border-width: 1px;
    background-color: #F2F7F9;
    color: #667D8D;
    border-radius: 5px;
    width: 100%;
    height: 30px;
    font-size: 15px;
    padding: 15px 10px 15px 10px;
    margin-bottom: 10px;
}

.inputActivoGen {
    border-width: 1px;
    border-radius: 5px;
    width: 100%;
    height: 30px;
    font-size: 15px;
    padding: 15px 10px 15px 10px;
    margin-bottom: 10px;

    background-color: white;
    color: #042354;
    border: 1px solid black;
}

.inputActivoGen:focus{
    outline: none;
}

.inputLargoMP {
    width: 85%;
}

.inputMenosPad {
    padding: 2px 10px 2px 10px !important;
}




/*otro */

.contenedor2 {
    border: 1px solid black;
    height: 90%;
    background-color: #fff;
    border-radius: 5px;
    margin: 0% !important;
    border-radius: 0px 5px 5px 5px;
    border-top: 15px solid #042354;
    background-color: #fff;
    padding: 20px;
    position: relative;
}

.titulo2 {
    width: 50% !important;

    font-size: 20px;
    font-family: sans-serif;
    background-color: #fff;
    padding: 5px;
    border-radius: 5px 5px 0px 0px;
    border: 1px solid black;
    height: 60%;
    text-align: center;
    font-family: "Montserrat", sans-serif;
}

.contTitulo2 {
    margin-bottom: 0px !important;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.contTitulo2 h2 {
    margin-bottom: 0px;
}


.tituloTipo3 {
    margin-top: -55px;

    width: 18%;
    height: 40px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.tituloPrim {
    font-size: 30px !important;
    font-weight: bold;
    margin-bottom: 20px;
}