.inicioPaginaAI {
    display: flex;
    flex-direction: column;
    font-family: 'Fira Sans', sans-serif;
    font-size: 20px;
    color: #042354;
    background-color: white;
    margin: 2% 0% 1.5% 0%;
    width: 95%;
    border: 1px solid black;
    border-radius: 5px;
    align-items: flex-end;
    padding: 2%;
    min-height: 90%;

}

.contenedor-rub {
    display: flex;
    align-items: center;

}

.ingresoCodigoAI{
    height: 35%;
}
.ingresoNombreAI {
    margin-left: 5%;
    height: 35%;

}

.ingresotextoAI {
    margin-left: 5%;
    margin-right: 5%;

    margin-top: 0.5%;
    margin-bottom: 0.5%;
    resize: none; /* Desactiva el redimensionamiento */
    padding: 2px 10px 2px 10px !important;
}

.form-control1 {
    font-size: 0.9rem;
    height: calc(2rem + 2px);
}

.labelAI {
    margin-bottom: 25px;
}

.inputREAI {
    margin-bottom: 20px;
}

.labelAI,
.tituloAI {
    width: 10%;
    display: flex;
    align-items: center;
}

.contenedorSuperiorAI {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 40px;
}


.contenedorInferiorAI {
    justify-content: space-between;
    align-items: center;
    
}

.inputNombreAI {
    height: 50px;
}

.izquierdaAI {
    text-align: left;
}

.derechaAI {
    flex-grow: 1;
    text-align: left;
}

.posBtnGuardarAI {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.btnGuardarAI {
    background-color: #042354;
}


.contenedorAI {
    display: flex;
    justify-content: space-between;
}


.alineacionAI {
    flex: 1;
    text-align: left;
}

.contDatosAI {
    margin-top: 2px;
}

.formContDatosAI {
    width: 100%;
    
}

.contfotoPerfilAI {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contfotoPerfilAI p {
    font-size: 50px;
    font-weight: bold;
}

.fotoPerfilAI {
    height: 170px !important;
    max-width: 25%;
    max-height: 25%;
}


.contIndicadoresEAI {
    border: 1px solid black;
    padding: 2%;
    width: 100%;
    
    background-color: #fff;
    border-radius: 5px;
    border-top: 15px solid #042354;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: auto !important;
    margin-bottom: 30px;
}

.tituloAI {
    margin-top: -55px;
    width: 17%;
}



.botonAceptarAI {
    background-color: #042354;
    width: 120px;
    height: 35px;
    font-size: 80%;
    margin-left: 10%;
    font-weight: bold;
}

.botonCancelarAI {
    background-color: #9E0520;
    width: 120px;
    height: 35px;
    font-size: 80%;
    margin-right: 10%;
    font-weight: bold;
}
.numMargin{
    margin-left: 5%;
}