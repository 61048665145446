.contenedorTablaGC2 {
    width: 95%;
    max-height: 320px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-Y: scroll;
}

.btnAniadirREAC2 {
    width: 115px;
    height: 35px;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: 14px;
    border: 0px;
    border-radius: 10px;

}

.btnEliminarREAC2 {
    width: 115px;
    height: 35px;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: 14px;
    border: 0px;
    border-radius: 10px;
    margin-left: 15px;


}