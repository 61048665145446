/*CONTENEDORES*/
.contenedorPrincipalAARF {
    display: flex;
    flex-direction: column;
    font-family: 'Fira Sans', sans-serif;
    color: #042354;
    margin: 1.5% 0% 1.5% 0%;
    width: 95%;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 20px;
    border: 1px solid black;
    padding: 2%;
    background-color: #fff;
    text-align: left;

    justify-content: left;
    align-items: left;
    margin-top: 2%;
    min-height: 95%;
}

/* Estilo para el contenedor de los radio buttons */
.contenedorRadio {
    display: flex;
    align-items: center;
    margin-left: 60px;
}

/* Estilo para los radio buttons */
.contenedorRadio input[type="radio"] {
    margin-right: 5px;
    /* Espacio entre el radio button y el texto */
}

/* Estilo para el texto del radio button */
.contenedorRadio label {
    font-weight: normal;
}

/* Estilo para el radio button seleccionado */
.contenedorRadio input[type="radio"]:checked+label {
    font-weight: bold;
}

.inputEspecialAARF{
    border: none;
    color: red;
    height: 15px;
    font-size: 16px;
    background-color: white;
}

.contenedor-codigoAARF {
    display: flex;
    align-items: center;
}

.contenedor-descripcionAARF {
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.contenedor-evidenciaAARF {
    display: flex;
    align-items: center;
    margin-top: 2%;
}

.contenedor-guardarAARF {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 18%;
}

.contenedor-indicadoresAARF {
    height: 60%;
}

.contIndicAARF {
    border: 1px solid black;
    padding: 2%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    margin-top: 2px;
    width: 100%;
}

.contenedor-botonesAARF {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 10%;
}

.contSup {
    width: 100%;
    margin-bottom: 20px;
}

.contenedor-indicadores-rellenoAARF {
    width: 100%;
    height: 100%;
    padding-top: 2%;
    font-weight: bold;
    color: #0072BC;
    align-items: flex-start;
    display: flex;
    justify-content: center;
}

.contDatosAARF {
    width: 95%;
    height: 5%;
}



.codigoAARF,
.descripcionAARF,
.evidenciaAARF {
    width: 20%;
    color: #042354;
    font-weight: bold;
    font-size: 90%;
    height: 20%;
}

.inputAARF {
    padding: 0 10px;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 1rem;
    color: #042354;
}

.form-control1AARF {
    font-size: 1rem;
    height: calc(2rem + 2px);
}

.form-control2AARF {
    font-size: 1rem;
    height: calc(4rem + 2px);
    width: 100%;
}

.rectanguloAzulAARF {
    background-color: #042354;
    height: 10px;
    width: 100%;
    margin: -0.2%;
    display: flex;
}

.tituloIDsAARF {
    background-color: #fff;
    padding: 5px;
    border-radius: 5px;
    margin-top: -55px;
    border: 1px solid black;
    width: 25%;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-family: 'Fira Sans', sans-serif;
}





/*BOTONES*/

.botonesSuperioresAARF {
    display: flex;
    width: 20%;
    justify-content: flex-end;
}


.btnAniadirAARF {
    background-color: #0072BC;
}

.btnEliminarAARF {
    background-color: #9E0520;
}

.btnGuardarAARF {
    background-color: #042354;
}


.botonAceptarAARF {
    background-color: #042354;
}

.botonCancelarAARF {
    background-color: #9E0520;
}